@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primaryColor: #525CEB;
  --secondaryTextColor: #494949;
  --bgColor: #f6f8fa;
  --secondaryColor: #0077ff;
}

body {
  font-family: "Poppins", sans-serif;
  background: var(--bgColor);
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(201, 205, 215, 0.2078431373);
  border: 1px solid rgba(142, 146, 156, 0.1254901961);
  border-radius: 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c9cdd7;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(141, 145, 157, 0.6941176471);
  cursor: grab;
}

.container {
  width: calc(100% - 550px);
  margin: 0 auto;
}
@media (max-width: 1800px) {
  .container {
    width: calc(100% - 200px);
  }
}
@media (max-width: 1024px) {
  .container {
    width: calc(100% - 100px);
  }
}
@media (max-width: 520px) {
  .container {
    width: calc(100% - 32px);
  }
}

a,
article,
button,
div,
span,
i,
b,
h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
ul,
li,
select,
label,
textarea {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  font-family: "Poppins", sans-serif;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  font-family: "Poppins", sans-serif;
}

input {
  -webkit-tap-highlight-color: transparent;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}

video::-internal-media-controls-overlay-cast-button {
  display: none;
}

textarea {
  resize: none;
  font-family: "Poppins", sans-serif;
}

::-moz-selection {
  color: white;
  background: #1d1d1d;
}

::selection {
  color: white;
  background: #1d1d1d;
}

p {
  font-family: "Poppins", sans-serif;
}

.pageLoading {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: white;
  z-index: 400;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pageLoading .spinLoader {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #eee;
  border-top-color: var(--primaryColor);
  animation: loadingSpin 1s linear infinite;
}
@keyframes loadingSpin {
  100% {
    transform: rotate(1turn);
  }
}

.shimmer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background: rgba(0, 0, 0, 0.822);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  display: none;
}

.loginPopup {
  position: fixed;
  z-index: 210;
  width: 550px;
  background: white;
  border-radius: 15px;
  transform: translate(-50%, -50%);
  top: 50%;
  overflow: hidden;
  left: 50%;
}
@media (max-width: 570px) {
  .loginPopup {
    width: 90%;
  }
}
.loginPopup .closeloginPopup {
  position: absolute;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.409);
  right: 20px;
  top: 20px;
  cursor: pointer;
  transition: 0.3s;
}
.loginPopup .closeloginPopup:hover {
  transition: 0.3s;
  background: white;
}
.loginPopup .closeloginPopup svg {
  width: 12px;
  height: 12px;
}
.loginPopup .loginPopupHead {
  width: 100%;
  padding: 40px;
  background-image: linear-gradient(-225deg, #00b8b5 0%, #85a8f4 48%, #d098ff 100%);
}
@media (max-width: 520px) {
  .loginPopup .loginPopupHead {
    padding: 40px 20px;
  }
}
.loginPopup .loginPopupHead h2 {
  color: white;
}
.loginPopup .loginPopupHead p {
  font-size: 18px;
  line-height: 1.4;
  margin-top: 10px;
  color: white;
}
@media (max-width: 520px) {
  .loginPopup .loginPopupHead p {
    font-size: 16px;
  }
}
.loginPopup .loginPopupBody {
  width: 100%;
  padding: 40px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
@media (max-width: 520px) {
  .loginPopup .loginPopupBody {
    padding: 40px 20px;
  }
}
@media (max-width: 480px) {
  .loginPopup .loginPopupBody {
    padding: 20px;
  }
}
.loginPopup .loginPopupBody p {
  font-size: 18px;
  line-height: 1.4;
  margin-bottom: 10px;
  color: var(--secondaryTextColor);
}
.loginPopup .loginPopupBody a {
  text-decoration: none;
  width: calc(50% - 10px);
  height: 50px;
  border: 1px solid var(--primaryColor);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 20px;
  gap: 10px;
  transition: 0.3s;
}
@media (max-width: 480px) {
  .loginPopup .loginPopupBody a {
    width: 100%;
  }
}
.loginPopup .loginPopupBody a svg {
  width: 18px;
  height: 18px;
}
.loginPopup .loginPopupBody .loginBtn {
  background: var(--primaryColor);
  color: white;
}
.loginPopup .loginPopupBody .loginBtn:hover {
  transition: 0.3s;
  background: white;
  color: var(--primaryColor);
}
.loginPopup .loginPopupBody .registerBtn {
  color: var(--primaryColor);
}
.loginPopup .loginPopupBody .registerBtn:hover {
  transition: 0.3s;
  background: var(--primaryColor);
  color: white;
}

nav {
  width: 100%;
  background: white;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
}
nav .navMain {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 520px) {
  nav .navMain {
    height: 60px;
  }
}
nav .navMain .navCol1 {
  display: flex;
  align-items: center;
}
nav .navMain .navCol1 .navLogo {
  height: 60px;
  width: 250px;
  text-decoration: none;
  display: flex;
  align-items: center;
}
@media (max-width: 520px) {
  nav .navMain .navCol1 .navLogo {
    height: 50px;
    width: 200px;
  }
}
nav .navMain .navCol1 .navLogo h2 {
  color: black;
  font-size: 22px;
  font-weight: 700;
}
nav .navMain .navCol1 .navLogo img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
nav .navMain .navCol1 .navLink {
  display: flex;
  margin-left: 100px;
}
@media (max-width: 1800px) {
  nav .navMain .navCol1 .navLink {
    margin-left: 60px;
  }
}
@media (max-width: 1360px) {
  nav .navMain .navCol1 .navLink {
    display: none;
  }
}
nav .navMain .navCol1 .navLink ul {
  display: flex;
  align-items: center;
  gap: 50px;
}
@media (max-width: 1800px) {
  nav .navMain .navCol1 .navLink ul {
    gap: 40px;
  }
}
nav .navMain .navCol1 .navLink ul li {
  list-style: none;
}
nav .navMain .navCol1 .navLink ul li a {
  text-decoration: none;
  color: rgb(26, 26, 26);
  font-size: 16px;
  padding: 10px 0px;
  position: relative;
}
@media (max-width: 1800px) {
  nav .navMain .navCol1 .navLink ul li a {
    font-size: 14px;
  }
}
nav .navMain .navCol1 .navLink ul li a:hover::after {
  display: block;
}
nav .navMain .navCol1 .navLink ul li a:hover::before {
  display: block;
}
nav .navMain .navCol1 .navLink ul li a::after {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background: var(--primaryColor);
  bottom: 0;
  left: calc(50% - 5px);
  transform: translateX(-50%);
  display: none;
}
nav .navMain .navCol1 .navLink ul li a::before {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background: var(--primaryColor);
  bottom: 0;
  left: calc(50% + 5px);
  transform: translateX(-50%);
  display: none;
}
nav .navMain .navCol1 .navLink ul li .navLinkActive {
  color: var(--primaryColor);
  font-weight: 600;
}
nav .navMain .navCol1 .navLink ul li .navLinkActive::after {
  display: block;
}
nav .navMain .navCol1 .navLink ul li .navLinkActive::before {
  display: block;
}
nav .navMain .navCol2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
nav .navMain .navCol2 .navSearch {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
}
@media (max-width: 850px) {
  nav .navMain .navCol2 .navSearch {
    width: 40px;
  }
}
@media (max-width: 600px) {
  nav .navMain .navCol2 .navSearch {
    margin-right: 20px;
  }
}
@media (max-width: 520px) {
  nav .navMain .navCol2 .navSearch {
    margin-right: 15px;
  }
}
nav .navMain .navCol2 .navSearch .navSearchBox {
  width: 100%;
  height: 40px;
  background: var(--bgColor);
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.3s;
  border: 1px solid transparent;
}
@media (max-width: 850px) {
  nav .navMain .navCol2 .navSearch .navSearchBox {
    padding: 0;
    justify-content: center;
  }
}
@media (max-width: 480px) {
  nav .navMain .navCol2 .navSearch .navSearchBox {
    background: none;
  }
}
nav .navMain .navCol2 .navSearch .navSearchBox:hover {
  transition: 0.3s;
  border: 1px solid var(--primaryColor);
}
nav .navMain .navCol2 .navSearch .navSearchBox p {
  font-size: 14px;
  color: var(--secondaryTextColor);
}
@media (max-width: 850px) {
  nav .navMain .navCol2 .navSearch .navSearchBox p {
    display: none;
  }
}
nav .navMain .navCol2 .navSearch .navSearchBox svg {
  width: 18px;
  height: 18px;
}
nav .navMain .navCol2 .navLogin {
  display: flex;
}
nav .navMain .navCol2 .navLogin .navLoginBtn {
  color: var(--primaryColor);
  padding: 0px 26px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
  gap: 10px;
  border: 1px solid var(--primaryColor);
  transition: 0.3s;
  display: none;
}
nav .navMain .navCol2 .navLogin .navLoginBtn:hover {
  transition: 0.3s;
  background: var(--primaryColor);
  color: white;
}
nav .navMain .navCol2 .navLogin .navLoginBtn svg {
  width: 18px;
  height: 18px;
}
nav .navMain .navCol2 .navLogin .navProfileBtn {
  display: flex;
  align-items: center;
  gap: 15px;
  background: var(--bgColor);
  height: 50px;
  padding: 0px 10px 0px 15px;
  border: 1px solid #f4f3f3;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;
}
@media (max-width: 480px) {
  nav .navMain .navCol2 .navLogin .navProfileBtn {
    padding: 0;
    height: auto;
    border: none;
    background: none;
  }
}
nav .navMain .navCol2 .navLogin .navProfileBtn:hover {
  transition: 0.3s;
  border-color: var(--primaryColor);
}
nav .navMain .navCol2 .navLogin .navProfileBtn .navProfileNavBar {
  width: 30px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
@media (max-width: 480px) {
  nav .navMain .navCol2 .navLogin .navProfileBtn .navProfileNavBar {
    display: none;
  }
}
nav .navMain .navCol2 .navLogin .navProfileBtn .navProfileNavBar span {
  width: 100%;
  height: 2px;
  background: var(--primaryColor);
}
nav .navMain .navCol2 .navLogin .navProfileBtn .navProfileIcon {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primaryColor);
}
@media (max-width: 480px) {
  nav .navMain .navCol2 .navLogin .navProfileBtn .navProfileIcon {
    background: var(--primaryColor);
    color: white;
  }
}
nav .navMain .navCol2 .navLogin .navProfileBtn .navProfileIcon svg {
  width: 15px;
  height: 15px;
}
nav .navMain .navCol2 .navBtnArea {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: 40px;
}
@media (max-width: 600px) {
  nav .navMain .navCol2 .navBtnArea {
    margin-right: 20px;
  }
}
@media (max-width: 520px) {
  nav .navMain .navCol2 .navBtnArea {
    margin-right: 15px;
  }
}
nav .navMain .navCol2 .navBtnArea .navBtnAreaBox {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--bgColor);
  color: black;
  transition: 0.3s;
  border: 1px solid transparent;
  position: relative;
}
@media (max-width: 480px) {
  nav .navMain .navCol2 .navBtnArea .navBtnAreaBox {
    background: none;
  }
}
nav .navMain .navCol2 .navBtnArea .navBtnAreaBox:hover {
  transition: 0.3s;
  border: 1px solid var(--primaryColor);
}
nav .navMain .navCol2 .navBtnArea .navBtnAreaBox:hover .navDropDown {
  display: block;
}
nav .navMain .navCol2 .navBtnArea .navBtnAreaBox .notificationActiveIcon {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background: #00c610;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 520px) {
  nav .navMain .navCol2 .navBtnArea .navBtnAreaBox .notificationActiveIcon {
    right: 8px;
    top: 5px;
  }
}
nav .navMain .navCol2 .navBtnArea .navBtnAreaBox .notificationActiveIcon::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background: rgba(0, 198, 16, 0.2431372549);
  animation: notifyActive 1s infinite linear;
  transform: scale(0);
}
@keyframes notifyActive {
  100% {
    transform: scale(1);
  }
}
nav .navMain .navCol2 .navBtnArea .navBtnAreaBox svg {
  width: 20px;
  height: 20px;
}
nav .navMain .navCol2 .navBtnArea .navBtnAreaBox .navDropDown {
  position: absolute;
  width: 300px;
  background: white;
  border-radius: 5px;
  max-height: 300px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid #eee;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.0509803922);
  overflow-y: auto;
  display: none;
}
@media (max-width: 480px) {
  nav .navMain .navCol2 .navBtnArea .navBtnAreaBox .navDropDown {
    right: -20px;
    left: inherit;
    transform: inherit;
  }
}
nav .navMain .navCol2 .navBtnArea .navBtnAreaBox .navDropDown ul {
  width: 100%;
  display: flex;
  flex-direction: column;
}
nav .navMain .navCol2 .navBtnArea .navBtnAreaBox .navDropDown ul li {
  list-style: none;
  position: relative;
}
nav .navMain .navCol2 .navBtnArea .navBtnAreaBox .navDropDown ul li::after {
  position: absolute;
  content: "";
  width: calc(100% - 40px);
  left: 20px;
  bottom: 0;
  height: 1px;
  background: var(--bgColor);
}
nav .navMain .navCol2 .navBtnArea .navBtnAreaBox .navDropDown ul li:nth-last-of-type(1)::after {
  display: none;
}
nav .navMain .navCol2 .navBtnArea .navBtnAreaBox .navDropDown ul li a {
  text-decoration: none;
  width: 100%;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
}
nav .navMain .navCol2 .navBtnArea .navBtnAreaBox .navDropDown ul li a:hover {
  transition: 0.3s;
  background: var(--bgColor);
}
nav .navMain .navCol2 .navBtnArea .navBtnAreaBox .navDropDown ul li a h2 {
  font-size: 16px;
  font-weight: 500;
  color: black;
}
nav .navMain .navCol2 .navBtnArea .navBtnAreaBox .navDropDown ul li a p {
  font-size: 12px;
  margin-top: 5px;
  color: var(--secondaryTextColor);
}

.navSearchStrip {
  position: fixed;
  width: calc(100% - 20px);
  background: white;
  z-index: 110;
  top: -350px;
  left: 10px;
  border-radius: 5px;
}
.navSearchStrip .closeNavSearchStrip {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bgColor);
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50px;
}
@media (max-width: 600px) {
  .navSearchStrip .closeNavSearchStrip {
    right: 20px;
    top: 20px;
  }
}
.navSearchStrip .closeNavSearchStrip:hover {
  transition: 0.3s;
  background: #eee;
}
.navSearchStrip .closeNavSearchStrip svg {
  width: 20px;
  height: 20px;
}
.navSearchStrip .navSearchStripMain {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 50px;
}
@media (max-width: 1024px) {
  .navSearchStrip .navSearchStripMain {
    height: 250px;
  }
}
@media (max-width: 600px) {
  .navSearchStrip .navSearchStripMain {
    margin-top: 20px;
    padding-bottom: 20px;
  }
}
.navSearchStrip .navSearchStripMain form {
  width: 100%;
  display: flex;
  align-items: center;
  background: white;
  border-radius: 10px;
  gap: 10px;
  margin-top: 10px;
}
@media (max-width: 600px) {
  .navSearchStrip .navSearchStripMain form {
    flex-wrap: wrap;
    gap: 15px;
  }
}
.navSearchStrip .navSearchStripMain form .formGroupBox {
  width: calc(100% - 210px);
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  display: none;
}
.navSearchStrip .navSearchStripMain form .formGroupBox .formGroup {
  width: 100%;
}
.navSearchStrip .navSearchStripMain form .formGroupBox .formGroup input {
  width: 100%;
  height: 60px;
  border: none;
  outline: none;
  font-size: 20px;
  padding: 0px 10px;
  color: black;
}
@media (max-width: 1024px) {
  .navSearchStrip .navSearchStripMain form .formGroupBox .formGroup input {
    height: 40px;
  }
}
.navSearchStrip .navSearchStripMain form .formBtnArea {
  width: 200px;
}
@media (max-width: 600px) {
  .navSearchStrip .navSearchStripMain form .formBtnArea {
    width: 100%;
  }
}
.navSearchStrip .navSearchStripMain form .formBtnArea .formSbtBtn {
  width: 100%;
  background: var(--primaryColor);
  color: white;
  height: 70px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 17px;
  font-size: 22px;
  transition: 0.3s;
}
@media (max-width: 1024px) {
  .navSearchStrip .navSearchStripMain form .formBtnArea .formSbtBtn {
    height: 55px;
  }
}
.navSearchStrip .navSearchStripMain form .formBtnArea .formSbtBtn:hover {
  transition: 0.3s;
  opacity: 0.7;
}
.navSearchStrip .navSearchStripMain form .formBtnArea .formSbtBtn svg {
  width: 22px;
  height: 22px;
}
.navSearchStrip .navSearchStripMain form .css-13cymwt-control {
  border: 1px solid transparent;
  background: var(--bgColor);
  border-radius: 10px;
}
.navSearchStrip .navSearchStripMain form .css-t3ipsp-control {
  outline: none !important;
  border: 1px solid var(--primaryColor);
  box-shadow: none !important;
}
.navSearchStrip .navSearchStripMain form .css-t3ipsp-control:hover {
  border-color: var(--primaryColor) !important;
}
.navSearchStrip .navSearchStripMain form .css-t3ipsp-control:focus {
  border-color: var(--primaryColor) !important;
}
.navSearchStrip .navSearchStripMain form .css-1u9des2-indicatorSeparator {
  display: none;
}
.navSearchStrip .navSearchStripMain form .mobileSearchInput {
  display: block;
  width: calc(100% - 210px);
}
@media (max-width: 600px) {
  .navSearchStrip .navSearchStripMain form .mobileSearchInput {
    width: 100%;
  }
}
.navSearchStrip .navSearchStripMain form .mobileSearchInput input {
  width: 100%;
  outline: none;
  font-size: 20px;
  padding: 0px 10px;
  color: black;
  outline: none;
  border: 1px solid #eee;
  background: var(--bgColor);
  height: 70px;
  border-radius: 10px;
}
@media (max-width: 1024px) {
  .navSearchStrip .navSearchStripMain form .mobileSearchInput input {
    height: 55px;
  }
}
@media (max-width: 520px) {
  .navSearchStrip .navSearchStripMain form .mobileSearchInput input {
    font-size: 16px;
  }
}
.navSearchStrip .navSearchStripMain form .mobileSearchInput input:focus {
  border-color: #aaa;
}

.navSearchStripActive {
  top: 10px;
  transition: 0.2s;
}

.sidemenu {
  position: fixed;
  z-index: 100;
  right: -500px;
  top: 0;
  height: 100vh;
  background: white;
  width: 400px;
}
@media (max-width: 480px) {
  .sidemenu {
    width: 100%;
  }
}
.sidemenu .closeSidemenu {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: var(--secondaryTextColor);
  border-radius: 5px;
  background: var(--bgColor);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}
.sidemenu .closeSidemenu:hover {
  transition: 0.3s;
  background: #eee;
}
.sidemenu .closeSidemenu svg {
  width: 15px;
  height: 15px;
}
.sidemenu .sidemenuHead {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  height: 90px;
  margin-top: 60px;
  border-bottom: 1px solid #eee;
}
.sidemenu .sidemenuHead .sidemenuHeadLogin {
  width: 100%;
  display: none;
}
.sidemenu .sidemenuHead .sidemenuHeadLogin .sidemenuLoginBtn {
  width: 100%;
  text-decoration: none;
  background: var(--primaryColor);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 20px;
  border-radius: 3px;
  color: white;
  gap: 15px;
  font-size: 20px;
  transition: 0.3s;
}
.sidemenu .sidemenuHead .sidemenuHeadLogin .sidemenuLoginBtn:hover {
  transition: 0.3s;
  opacity: 0.7;
}
.sidemenu .sidemenuHead .sidemenuHeadLogin .sidemenuLoginBtn svg {
  width: 20px;
  height: 20px;
}
.sidemenu .sidemenuHead .sidemenuHeadProfile {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: flex-start;
}
.sidemenu .sidemenuHead .sidemenuHeadProfile .sidemenuHeadCol1 {
  width: 60px;
  height: 60px;
}
.sidemenu .sidemenuHead .sidemenuHeadProfile .sidemenuHeadCol1 .sidemenuProfileIcon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: var(--bgColor);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f4f3f3;
}
.sidemenu .sidemenuHead .sidemenuHeadProfile .sidemenuHeadCol1 .sidemenuProfileIcon svg {
  width: 20px;
  height: 20px;
  color: #aaa;
}
.sidemenu .sidemenuHead .sidemenuHeadProfile .sidemenuHeadCol1 .sidemenuProfileIcon img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.sidemenu .sidemenuHead .sidemenuHeadProfile .sidemenuHeadCol2 {
  width: calc(100% - 70px);
  display: flex;
  flex-direction: column;
}
.sidemenu .sidemenuHead .sidemenuHeadProfile .sidemenuHeadCol2 .sidemenuProfileName {
  font-size: 20px;
  font-weight: 600;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.sidemenu .sidemenuHead .sidemenuHeadProfile .sidemenuHeadCol2 .sidemenuPhone {
  font-size: 12px;
  font-weight: 400;
  margin-top: 3px;
  color: var(--secondaryTextColor);
}
.sidemenu .sidemenuHead .sidemenuHeadProfile .sidemenuHeadCol2 .sidemenuProfileViewBtn {
  text-decoration: none;
  color: var(--secondaryColor);
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.sidemenu .sidemenuHead .sidemenuHeadProfile .sidemenuHeadCol2 .sidemenuProfileViewBtn:hover {
  text-decoration: underline;
}
.sidemenu .sidemenuHead .sidemenuHeadProfile .sidemenuHeadCol2 .sidemenuProfileViewBtn svg {
  width: 14px;
  height: 14px;
}
.sidemenu .sidemenuBody {
  width: 100%;
  height: calc(100vh - 150px);
  overflow-y: auto;
}
.sidemenu .sidemenuBody ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: 10px solid var(--bgColor);
  padding: 10px 0px;
}
.sidemenu .sidemenuBody ul:nth-of-type(1) {
  border-top: none;
}
.sidemenu .sidemenuBody ul li {
  list-style: none;
}
.sidemenu .sidemenuBody ul li a {
  text-decoration: none;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 14px;
  color: black;
}
.sidemenu .sidemenuBody ul li a:hover {
  background: #f8f6f6;
}
.sidemenu .sidemenuBody ul li a svg {
  width: 15px;
  height: 15px;
  color: var(--secondaryTextColor);
}
.sidemenu .sidemenuBody ul li .sidemenuLinkActive {
  color: var(--secondaryColor);
  background: #f8f6f6;
}
.sidemenu .sidemenuBody ul li .sidemenuLinkActive svg {
  color: var(--secondaryColor);
}
.sidemenu .sidemenuBody ul li .logOutBtn {
  opacity: 0.5;
}

.sidemenuActive {
  right: 0;
  transition: 0.3s;
}

.mainTitle {
  font-size: 4em;
  font-weight: 400;
  line-height: 1.2;
}
@media (max-width: 1800px) {
  .mainTitle {
    font-size: 3.5em;
  }
}
@media (max-width: 1024px) {
  .mainTitle {
    font-size: 3em;
  }
}
@media (max-width: 768px) {
  .mainTitle {
    font-size: 2.5em;
  }
}
@media (max-width: 520px) {
  .mainTitle {
    font-size: 2.2em;
  }
}

.titleHead {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.titleHead .titleMoreLink {
  display: flex;
}
.titleHead .titleMoreLink a {
  text-decoration: none;
  padding: 10px 25px;
  border: 1px solid var(--primaryColor);
  color: var(--primaryColor);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: white;
  border-radius: 10px;
  font-weight: 500;
  transition: 0.3s;
}
.titleHead .titleMoreLink a:hover {
  transition: 0.3s;
  background: var(--primaryColor);
  color: white;
}
.titleHead .titleMoreLink a svg {
  width: 18px;
  height: 18px;
}

.title {
  font-size: 28px;
  font-weight: 600;
}
@media (max-width: 1024px) {
  .title {
    font-size: 24px;
  }
}
@media (max-width: 520px) {
  .title {
    font-size: 22px;
  }
}

.subTitle {
  font-size: 18px;
  font-weight: 600;
}

footer {
  width: 100%;
  padding: 100px 0px 20px 0px;
  background: #0d101f;
}
@media (max-width: 520px) {
  footer {
    padding: 60px 0px 20px 0px;
  }
}
footer .footerMain {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
footer .footerMain .footerCol1 {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1024px) {
  footer .footerMain .footerCol1 {
    flex: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
  }
}
footer .footerMain .footerCol1 .footerLogo {
  height: 90px;
  width: 220px;
  text-decoration: none;
  display: flex;
  align-items: center;
  background: white;
  padding: 10px;
  border-radius: 3px;
  overflow: hidden;
}
@media (max-width: 520px) {
  footer .footerMain .footerCol1 .footerLogo {
    width: 200px;
    height: 80px;
  }
}
footer .footerMain .footerCol1 .footerLogo img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
footer .footerMain .footerCol1 .footerSocialLinks {
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 520px) {
  footer .footerMain .footerCol1 .footerSocialLinks {
    width: 100%;
  }
}
footer .footerMain .footerCol1 .footerSocialLinks a {
  text-decoration: none;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #303848;
  color: white;
  transition: 0.3s;
}
@media (max-width: 520px) {
  footer .footerMain .footerCol1 .footerSocialLinks a {
    width: 45px;
    height: 45px;
  }
}
footer .footerMain .footerCol1 .footerSocialLinks a:hover {
  transition: 0.3s;
  background: var(--primaryColor);
  border-color: var(--primaryColor);
}
footer .footerMain .footerCol1 .footerSocialLinks a svg {
  width: 32px;
  height: 32px;
}
@media (max-width: 520px) {
  footer .footerMain .footerCol1 .footerSocialLinks a svg {
    width: 30px;
    height: 30px;
  }
}
footer .footerMain .footerCol2 {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}
@media (max-width: 1024px) {
  footer .footerMain .footerCol2 {
    flex: 100%;
    margin-top: 80px;
  }
}
footer .footerMain .footerCol2 .footerBox1 {
  display: flex;
  flex-direction: column;
  width: 200px;
}
@media (max-width: 1024px) {
  footer .footerMain .footerCol2 .footerBox1 {
    width: calc(33.3333333333% - 35px);
  }
}
@media (max-width: 520px) {
  footer .footerMain .footerCol2 .footerBox1 {
    width: 100%;
  }
}
footer .footerMain .footerCol2 .footerBox1 h2 {
  font-size: 20px;
  color: white;
  font-weight: 400;
}
footer .footerMain .footerCol2 .footerBox1 ul {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
}
footer .footerMain .footerCol2 .footerBox1 ul li {
  width: 100%;
  list-style: none;
}
footer .footerMain .footerCol2 .footerBox1 ul li a {
  text-decoration: none;
  color: #9fa6bb;
  font-size: 16px;
  transition: 0.3s;
}
footer .footerMain .footerCol2 .footerBox1 ul li a:hover {
  color: white;
  transition: 0.3s;
}
footer .footerMain .footerCol2 .footerBox2 {
  display: flex;
  flex-direction: column;
  width: 200px;
}
@media (max-width: 1024px) {
  footer .footerMain .footerCol2 .footerBox2 {
    width: calc(33.3333333333% - 35px);
  }
}
@media (max-width: 520px) {
  footer .footerMain .footerCol2 .footerBox2 {
    width: 100%;
  }
}
footer .footerMain .footerCol2 .footerBox2 h2 {
  font-size: 20px;
  color: white;
  font-weight: 400;
  width: -moz-fit-content;
  width: fit-content;
}
footer .footerMain .footerCol2 .footerBox2 ul {
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 20px;
}
footer .footerMain .footerCol2 .footerBox2 ul li {
  flex: 100%;
  list-style: none;
}
footer .footerMain .footerCol2 .footerBox2 ul li a {
  text-decoration: none;
  color: #9fa6bb;
  font-size: 16px;
  transition: 0.3s;
}
footer .footerMain .footerCol2 .footerBox2 ul li a:hover {
  color: white;
  transition: 0.3s;
}
footer .footerMain .footerCol2 .footerBox3 {
  display: flex;
  flex-direction: column;
  width: 120px;
}
@media (max-width: 1024px) {
  footer .footerMain .footerCol2 .footerBox3 {
    width: calc(33.3333333333% - 35px);
  }
}
@media (max-width: 520px) {
  footer .footerMain .footerCol2 .footerBox3 {
    width: 100%;
  }
}
footer .footerMain .footerCol2 .footerBox3 h2 {
  font-size: 20px;
  color: white;
  font-weight: 400;
  width: -moz-fit-content;
  width: fit-content;
}
footer .footerMain .footerCol2 .footerBox3 ul {
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 20px;
}
footer .footerMain .footerCol2 .footerBox3 ul li {
  flex: 100%;
  list-style: none;
}
footer .footerMain .footerCol2 .footerBox3 ul li a {
  text-decoration: none;
  color: #9fa6bb;
  font-size: 16px;
  transition: 0.3s;
}
footer .footerMain .footerCol2 .footerBox3 ul li a:hover {
  color: white;
  transition: 0.3s;
}
footer .footerStrip {
  width: 100%;
  border-top: 1px solid #303848;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 20px;
}
footer .footerStrip .footerStripCol1 {
  display: flex;
}
footer .footerStrip .footerStripCol1 p {
  color: white;
  font-size: 14px;
  font-weight: 300;
}
footer .footerStrip .footerStripCol2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}
footer .footerStrip .footerStripCol2 p {
  color: white;
  font-size: 14px;
  font-weight: 300;
}
footer .footerStrip .footerStripCol2 svg {
  color: white;
  width: 20px;
  height: 20px;
}
footer .footerStrip .footerStripCol2 .footerStripLogo {
  width: 60px;
}
footer .footerStrip .footerStripCol2 .footerStripLogo img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  filter: grayscale(1);
}

.jobBox {
  width: calc(50% - 15px);
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  text-decoration: none;
  background: white;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
  z-index: 0;
}
@media (max-width: 1200px) {
  .jobBox {
    width: calc(50% - 10px);
  }
}
@media (max-width: 768px) {
  .jobBox {
    width: 100%;
  }
}
.jobBox:hover {
  transition: 0.3s;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.0509803922);
  border-color: var(--primaryColor);
}
.jobBox::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: #f0f2ff;
  background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
}
.jobBox .jobBoxLink {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.jobBox .jobBoxHead {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px;
}
.jobBox .jobBoxHead .jobBoxHeadCol1 {
  display: flex;
  align-items: center;
}
.jobBox .jobBoxHead .jobBoxHeadCol1 .jobBoxProfile {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.jobBox .jobBoxHead .jobBoxHeadCol1 .jobBoxProfile .jobBoxProfileIcon {
  width: 50px;
  height: 50px;
  padding: 3px;
  border-radius: 15px;
  overflow: hidden;
  background: white;
  display: flex;
}
.jobBox .jobBoxHead .jobBoxHeadCol1 .jobBoxProfile .jobBoxProfileIcon img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.jobBox .jobBoxHead .jobBoxHeadCol1 .jobBoxProfile .jobBoxProfileTitle {
  display: flex;
  flex-direction: column;
  width: calc(100% - 60px);
}
.jobBox .jobBoxHead .jobBoxHeadCol1 .jobBoxProfile .jobBoxProfileTitle .jobCompany {
  font-size: 14px;
  font-weight: 400;
  color: var(--secondaryTextColor);
  color: #818181;
  width: 100%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: none;
}
.jobBox .jobBoxHead .jobBoxHeadCol1 .jobBoxProfile .jobBoxProfileTitle .jobTitleName {
  font-size: 18px;
  font-weight: 500;
  color: var(--primaryColor);
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.jobBox .jobBoxHead .jobBoxHeadCol2 {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.jobBox .jobBoxHead .jobBoxHeadCol2 .jobBoxSaveBtn {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondaryTextColor);
  position: relative;
  z-index: 2;
  cursor: pointer;
  transition: 0.3s;
  background: white;
}
@media (max-width: 520px) {
  .jobBox .jobBoxHead .jobBoxHeadCol2 .jobBoxSaveBtn {
    width: 30px;
    height: 30px;
  }
}
.jobBox .jobBoxHead .jobBoxHeadCol2 .jobBoxSaveBtn:hover {
  transition: 0.3s;
  color: black;
  background: #eee;
}
.jobBox .jobBoxHead .jobBoxHeadCol2 .jobBoxSaveBtn svg {
  width: 20px;
  height: 20px;
}
@media (max-width: 520px) {
  .jobBox .jobBoxHead .jobBoxHeadCol2 .jobBoxSaveBtn svg {
    width: 15px;
    height: 15px;
  }
}
.jobBox .jobBoxHead .jobBoxHeadCol2 .jobBoxShareBtn {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondaryTextColor);
  position: relative;
  z-index: 2;
  cursor: pointer;
  transition: 0.3s;
  background: white;
}
@media (max-width: 520px) {
  .jobBox .jobBoxHead .jobBoxHeadCol2 .jobBoxShareBtn {
    width: 30px;
    height: 30px;
  }
}
.jobBox .jobBoxHead .jobBoxHeadCol2 .jobBoxShareBtn:hover {
  transition: 0.3s;
  color: var(--primaryColor);
  background: #eee;
}
.jobBox .jobBoxHead .jobBoxHeadCol2 .jobBoxShareBtn svg {
  width: 20px;
  height: 20px;
}
@media (max-width: 520px) {
  .jobBox .jobBoxHead .jobBoxHeadCol2 .jobBoxShareBtn svg {
    width: 15px;
    height: 15px;
  }
}
.jobBox .jobBoxBody {
  width: 100%;
  border-top: 1px solid #fff;
  padding: 15px 15px 0px 15px;
}
.jobBox .jobBoxBody ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 15px;
  -moz-column-gap: 25px;
       column-gap: 25px;
}
.jobBox .jobBoxBody ul li {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  z-index: 0;
}
.jobBox .jobBoxBody ul li:nth-of-type(1)::after {
  display: none;
}
.jobBox .jobBoxBody ul li::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 1px;
  background: #eee;
  left: -12.5px;
  top: 0;
}
.jobBox .jobBoxBody ul li .icon {
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondaryTextColor);
}
.jobBox .jobBoxBody ul li .icon svg {
  width: 14px;
  height: 14px;
}
.jobBox .jobBoxBody ul li p {
  font-size: 14px;
  font-weight: 500;
  color: var(--secondaryTextColor);
}
.jobBox .jobBoxBody ul .openings {
  background: #E7FBE6;
  padding: 4px 10px;
  border-radius: 50px;
}
.jobBox .jobBoxBody ul .openings p {
  color: #067c06;
}
.jobBox .jobBoxBody .jobDescription {
  width: 100%;
  margin-top: 15px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
}
.jobBox .jobBoxBody .jobDescription .icon {
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  color: var(--secondaryTextColor);
}
.jobBox .jobBoxBody .jobDescription .icon svg {
  width: 14px;
  height: 14px;
}
.jobBox .jobBoxBody .jobDescription p {
  width: calc(100% - 22px);
  font-size: 13px;
  color: var(--secondaryTextColor);
  line-height: 1.4;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.jobBox .jobBoxFooter {
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #fff;
  margin-top: 15px;
}
.jobBox .jobBoxFooter .jobBoxFooterCol1 {
  display: flex;
  align-items: center;
  gap: 10px;
}
.jobBox .jobBoxFooter .jobBoxFooterCol1 .postedDate {
  display: flex;
  align-items: center;
}
.jobBox .jobBoxFooter .jobBoxFooterCol1 .postedDate p {
  font-size: 12px;
  color: black;
  opacity: 0.7;
}
.jobBox .jobBoxFooter .jobBoxFooterCol2 {
  display: flex;
  align-items: center;
}
.jobBox .jobBoxFooter .jobBoxFooterCol2 .jobBoxApplyBtn {
  padding: 0px 20px;
  height: 35px;
  background: var(--primaryColor);
  color: white;
  font-size: 14px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  transition: 0.3s;
  position: relative;
  z-index: 2;
  cursor: pointer;
  text-decoration: none;
}
.jobBox .jobBoxFooter .jobBoxFooterCol2 .jobBoxApplyBtn:hover {
  transition: 0.3s;
  opacity: 0.7;
}
.jobBox .jobBoxFooter .jobBoxFooterCol2 .jobBoxApplyBtnDisabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.loaded .jobBoxHead .jobBoxHeadCol1 .jobBoxProfile .jobBoxProfileIcon {
  position: relative;
  z-index: 0;
  background: #f5f5f5;
}
.loaded .jobBoxHead .jobBoxHeadCol1 .jobBoxProfile .jobBoxProfileIcon::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: -100%;
  z-index: -1;
  background: rgba(222, 222, 222, 0.5490196078);
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.3137254902);
  filter: blur(15px);
  opacity: 0.5;
  animation: lazyLoading1 1.5s linear infinite;
}
@keyframes lazyLoading1 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
.loaded .jobBoxHead .jobBoxHeadCol1 .jobBoxProfile .jobBoxProfileIcon img {
  display: none;
}
.loaded .jobBoxHead .jobBoxHeadCol1 .jobBoxProfile .jobBoxProfileTitle .jobCompany {
  position: relative;
  overflow: hidden;
}
.loaded .jobBoxHead .jobBoxHeadCol1 .jobBoxProfile .jobBoxProfileTitle .jobCompany::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  border-radius: 5px;
  z-index: 1;
  left: 0;
  top: 0;
}
.loaded .jobBoxHead .jobBoxHeadCol1 .jobBoxProfile .jobBoxProfileTitle .jobCompany::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  border-radius: 5px;
  z-index: 2;
  left: 0;
  top: 0;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: lazyLoading3 1.5s linear infinite;
}
@keyframes lazyLoading3 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
.loaded .jobBoxHead .jobBoxHeadCol1 .jobBoxProfile .jobBoxProfileTitle .jobTitleName {
  position: relative;
  overflow: hidden;
}
.loaded .jobBoxHead .jobBoxHeadCol1 .jobBoxProfile .jobBoxProfileTitle .jobTitleName::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  border-radius: 5px;
  z-index: 1;
  left: 0;
  top: 0;
}
.loaded .jobBoxHead .jobBoxHeadCol1 .jobBoxProfile .jobBoxProfileTitle .jobTitleName::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  border-radius: 5px;
  z-index: 2;
  left: 0;
  top: 0;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: lazyLoading3 1.5s linear infinite;
}
@keyframes lazyLoading3 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
.loaded .jobBoxHead .jobBoxHeadCol2 .jobBoxSaveBtn {
  position: relative;
  overflow: hidden;
}
.loaded .jobBoxHead .jobBoxHeadCol2 .jobBoxSaveBtn::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  border-radius: 5px;
  z-index: 1;
  left: 0;
  top: 0;
}
.loaded .jobBoxHead .jobBoxHeadCol2 .jobBoxSaveBtn::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  border-radius: 5px;
  z-index: 2;
  left: 0;
  top: 0;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: lazyLoading3 1.5s linear infinite;
}
@keyframes lazyLoading3 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
.loaded .jobBoxHead .jobBoxHeadCol2 .jobBoxShareBtn {
  position: relative;
  overflow: hidden;
}
.loaded .jobBoxHead .jobBoxHeadCol2 .jobBoxShareBtn::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  border-radius: 5px;
  z-index: 1;
  left: 0;
  top: 0;
}
.loaded .jobBoxHead .jobBoxHeadCol2 .jobBoxShareBtn::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  border-radius: 5px;
  z-index: 2;
  left: 0;
  top: 0;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: lazyLoading3 1.5s linear infinite;
}
@keyframes lazyLoading3 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
.loaded .jobBoxBody ul li {
  position: relative;
  overflow: hidden;
}
.loaded .jobBoxBody ul li::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  border-radius: 5px;
  z-index: 1;
  left: 0;
  top: 0;
  display: block !important;
}
.loaded .jobBoxBody ul li::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  border-radius: 5px;
  z-index: 2;
  left: 0;
  top: 0;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: lazyLoading3 1.5s linear infinite;
}
@keyframes lazyLoading3 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
.loaded .jobBoxBody .jobDescription {
  position: relative;
  overflow: hidden;
}
.loaded .jobBoxBody .jobDescription::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  border-radius: 5px;
  z-index: 1;
  left: 0;
  top: 0;
}
.loaded .jobBoxBody .jobDescription::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  border-radius: 5px;
  z-index: 2;
  left: 0;
  top: 0;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: lazyLoading3 1.5s linear infinite;
}
@keyframes lazyLoading3 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
.loaded .jobBoxFooter .jobBoxFooterCol1 .postedDate {
  position: relative;
  overflow: hidden;
}
.loaded .jobBoxFooter .jobBoxFooterCol1 .postedDate::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  border-radius: 5px;
  z-index: 1;
  left: 0;
  top: 0;
}
.loaded .jobBoxFooter .jobBoxFooterCol1 .postedDate::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  border-radius: 5px;
  z-index: 2;
  left: 0;
  top: 0;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: lazyLoading3 1.5s linear infinite;
}
@keyframes lazyLoading3 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
.loaded .jobBoxFooter .jobBoxFooterCol1 .jobOpenings {
  position: relative;
  overflow: hidden;
}
.loaded .jobBoxFooter .jobBoxFooterCol1 .jobOpenings::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  border-radius: 5px;
  z-index: 1;
  left: 0;
  top: 0;
}
.loaded .jobBoxFooter .jobBoxFooterCol1 .jobOpenings::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  border-radius: 5px;
  z-index: 2;
  left: 0;
  top: 0;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: lazyLoading3 1.5s linear infinite;
}
@keyframes lazyLoading3 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
.loaded .jobBoxFooter .jobBoxFooterCol2 .jobBoxApplyBtn {
  position: relative;
  overflow: hidden;
  border-radius: 0;
}
.loaded .jobBoxFooter .jobBoxFooterCol2 .jobBoxApplyBtn::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  z-index: 1;
  left: 0;
  top: 0;
}
.loaded .jobBoxFooter .jobBoxFooterCol2 .jobBoxApplyBtn::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  z-index: 2;
  left: 0;
  top: 0;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: lazyLoading3 1.5s linear infinite;
}
@keyframes lazyLoading3 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.backBtnStrip {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.backBtnStrip .backBtnStripCol1 {
  display: flex;
  align-items: center;
}
.backBtnStrip .backBtnStripCol1 .backBtn {
  display: flex;
  align-items: center;
  color: var(--secondaryColor);
  gap: 10px;
  font-size: 16px;
  cursor: pointer;
}
.backBtnStrip .backBtnStripCol1 .backBtn svg {
  width: 16px;
  height: 16px;
}

.jobWraperLoadMore {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 480px) {
  .jobWraperLoadMore {
    margin-top: 30px;
  }
}
.jobWraperLoadMore .jobWraperLoadMoreBtn {
  padding: 10px 20px;
  background: #f9f9f9;
  color: var(--secondaryColor);
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;
}
.jobWraperLoadMore .jobWraperLoadMoreBtn:hover {
  transition: 0.3s;
  opacity: 0.7;
}

.noDataFound {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: white;
  padding: 40px 20px;
  border-radius: 15px;
}
.noDataFound .noDataFoundIcon {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--bgColor);
  color: #aaa;
}
.noDataFound .noDataFoundIcon svg {
  width: 30px;
  height: 30px;
}
.noDataFound .noDataFoundIcon img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.noDataFound .noDataFoundDetails {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.noDataFound .noDataFoundDetails h3 {
  font-size: 22px;
  font-weight: 500;
  text-align: center;
}
.noDataFound .noDataFoundDetails p {
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  color: var(--secondaryTextColor);
  line-height: 1.3;
  width: 75%;
}
@media (max-width: 768px) {
  .noDataFound .noDataFoundDetails p {
    width: 100%;
  }
}
.noDataFound .noDataFoundDetails .noDataFoundClearFIlterBtn {
  background: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  padding: 0px 25px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  transition: 0.3s;
  color: var(--secondaryColor);
  margin-top: 20px;
  text-decoration: none;
  gap: 10px;
}
.noDataFound .noDataFoundDetails .noDataFoundClearFIlterBtn:hover {
  transition: 0.3s;
  background: none;
}
.noDataFound .noDataFoundDetails .noDataFoundClearFIlterBtn svg {
  width: 17px;
  height: 17px;
}

.deleteConfirmPopup {
  position: fixed;
  z-index: 210;
  width: 700px;
  background: white;
  border-radius: 15px;
  padding: 60px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
@media (max-width: 750px) {
  .deleteConfirmPopup {
    width: 90%;
  }
}
@media (max-width: 480px) {
  .deleteConfirmPopup {
    width: 95%;
    padding: 20px;
  }
}
.deleteConfirmPopup .deleteConfirmPopupMain {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.deleteConfirmPopup .deleteConfirmPopupMain h2 {
  font-size: 22px;
  font-weight: 600;
}
@media (max-width: 480px) {
  .deleteConfirmPopup .deleteConfirmPopupMain h2 {
    font-size: 20px;
  }
}
.deleteConfirmPopup .deleteConfirmPopupMain .deleteConfirmPopupFooter {
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}
.deleteConfirmPopup .deleteConfirmPopupMain .deleteConfirmPopupFooter .closeDeleteConfirmPopup {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: var(--primaryColor);
  cursor: pointer;
  padding: 0px 20px;
  border-radius: 10px;
  background: #f9f9f9;
  transition: 0.3s;
}
.deleteConfirmPopup .deleteConfirmPopupMain .deleteConfirmPopupFooter .closeDeleteConfirmPopup:hover {
  transition: 0.3s;
  background: #fff;
}
.deleteConfirmPopup .deleteConfirmPopupMain .deleteConfirmPopupFooter .confirmDeleteConfirmPopup {
  height: 40px;
  padding: 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: white;
  background: var(--primaryColor);
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;
}
.deleteConfirmPopup .deleteConfirmPopupMain .deleteConfirmPopupFooter .confirmDeleteConfirmPopup:hover {
  transition: 0.3s;
  opacity: 0.7;
}

.toast-success .Toastify__progress-bar {
  background-color: #4c5caf; /* Example: Green color for success */
}

.toast-error .Toastify__progress-bar {
  background-color: #f44336; /* Example: Red color for error */
}

.jobAppliedSuccessPopup {
  position: fixed;
  z-index: 200;
  width: 700px;
  background: white;
  border-radius: 15px;
  padding: 60px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  overflow: hidden;
}
@media (max-width: 750px) {
  .jobAppliedSuccessPopup {
    width: 90%;
  }
}
@media (max-width: 480px) {
  .jobAppliedSuccessPopup {
    width: 95%;
    padding: 20px;
  }
}
.jobAppliedSuccessPopup::after {
  position: absolute;
  content: "";
  z-index: -1;
  background: #f2fafa;
  height: 60%;
  width: 100%;
  bottom: 0;
  left: 0;
}
.jobAppliedSuccessPopup .jobAppliedSuccessPopupMain {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.jobAppliedSuccessPopup .jobAppliedSuccessPopupMain .jobAppliedSuccessPopupImg {
  width: 200px;
}
.jobAppliedSuccessPopup .jobAppliedSuccessPopupMain .jobAppliedSuccessPopupImg img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.jobAppliedSuccessPopup .jobAppliedSuccessPopupMain .jobAppliedSuccessPopupDetails {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}
.jobAppliedSuccessPopup .jobAppliedSuccessPopupMain .jobAppliedSuccessPopupDetails h2 {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
}
.jobAppliedSuccessPopup .jobAppliedSuccessPopupMain .jobAppliedSuccessPopupDetails p {
  text-align: center;
  font-size: 16px;
  color: var(--secondaryTextColor);
  margin-top: 15px;
  line-height: 1.5;
  width: 75%;
  font-weight: 300;
}
@media (max-width: 550px) {
  .jobAppliedSuccessPopup .jobAppliedSuccessPopupMain .jobAppliedSuccessPopupDetails p {
    width: 85%;
  }
}
@media (max-width: 480px) {
  .jobAppliedSuccessPopup .jobAppliedSuccessPopupMain .jobAppliedSuccessPopupDetails p {
    width: 100%;
  }
}
.jobAppliedSuccessPopup .jobAppliedSuccessPopupMain .jobAppliedSuccessPopupDetails .okBtn {
  padding: 10px 30px;
  background: var(--primaryColor);
  color: white;
  font-size: 18px;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 30px;
  transition: 0.3s;
  text-decoration: none;
}
.jobAppliedSuccessPopup .jobAppliedSuccessPopupMain .jobAppliedSuccessPopupDetails .okBtn:hover {
  transition: 0.3s;
  opacity: 0.7;
}

.profileFormPopup {
  position: fixed;
  z-index: 200;
  width: 700px;
  background: white;
  border-radius: 15px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
@media (max-width: 750px) {
  .profileFormPopup {
    width: 90%;
  }
}
@media (max-width: 480px) {
  .profileFormPopup {
    width: 95%;
  }
}
.profileFormPopup .profileFormPopupHead {
  width: 100%;
  padding: 15px 50px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 480px) {
  .profileFormPopup .profileFormPopupHead {
    padding: 12px 15px;
  }
}
.profileFormPopup .profileFormPopupBody {
  width: 100%;
  max-height: 65vh;
  overflow-y: auto;
  padding: 15px 50px;
}
@media (max-width: 480px) {
  .profileFormPopup .profileFormPopupBody {
    padding: 12px 15px;
  }
}
.profileFormPopup .profileFormPopupBody form {
  width: 100%;
  display: flex;
  -moz-column-gap: 40px;
       column-gap: 40px;
  row-gap: 30px;
  flex-wrap: wrap;
}
.profileFormPopup .profileFormPopupBody form .formGroup {
  width: calc(50% - 20px);
  display: flex;
  flex-direction: column;
}
@media (max-width: 550px) {
  .profileFormPopup .profileFormPopupBody form .formGroup {
    width: 100%;
  }
}
.profileFormPopup .profileFormPopupBody form .formGroup label {
  font-size: 14px;
  font-weight: 500;
  color: var(--secondaryTextColor);
}
.profileFormPopup .profileFormPopupBody form .formGroup input {
  width: 100%;
  height: 40px;
  border: 1px solid #aaa;
  margin-top: 10px;
  border-radius: 5px;
  outline: none;
  padding: 0px 20px;
  font-size: 14px;
  font-weight: 500;
}
.profileFormPopup .profileFormPopupBody form .formGroup input:focus {
  border-color: var(--primaryColor);
}
.profileFormPopup .profileFormPopupBody form .formGroup input:disabled {
  background: var(--bgColor);
}
.profileFormPopup .profileFormPopupBody form .formGroup .phoneInput {
  width: 100%;
  display: flex;
  align-items: center;
}
.profileFormPopup .profileFormPopupBody form .formGroup .phoneInput input {
  border-radius: 0px 5px 5px 0px;
}
.profileFormPopup .profileFormPopupBody form .formGroup .phoneInput select {
  width: -moz-fit-content;
  width: fit-content;
  height: 40px;
  background: #ececec;
  border: 1px solid #aaa;
  border-right: none;
  border-radius: 5px 0px 0px 5px;
  outline: none;
  margin-top: 10px;
  padding: 0px 5px;
  outline: none;
  width: fit-content;
  font-size: 12px;
  font-weight: 500;
}
@media (max-width: 520px) {
  .profileFormPopup .profileFormPopupBody form .formGroup .phoneInput select {
    height: 50px;
  }
}
.profileFormPopup .profileFormPopupBody form .formGroup textarea {
  width: 100%;
  height: 100px;
  border: 1px solid #aaa;
  margin-top: 10px;
  border-radius: 5px;
  outline: none;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
}
.profileFormPopup .profileFormPopupBody form .formGroup textarea:focus {
  border-color: var(--primaryColor);
}
.profileFormPopup .profileFormPopupBody form .formGroup textarea:disabled {
  background: var(--bgColor);
}
.profileFormPopup .profileFormPopupBody form .formGroup .formRadioGroup {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 25px;
  -moz-column-gap: 40px;
       column-gap: 40px;
  margin-top: 20px;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formRadioGroup .formRadioGroupBox {
  min-width: calc(50% - 20px);
  max-width: 100%;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formRadioGroup .formRadioGroupBox input {
  display: none;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formRadioGroup .formRadioGroupBox input:checked ~ label .radioIcon {
  border-color: var(--primaryColor);
}
.profileFormPopup .profileFormPopupBody form .formGroup .formRadioGroup .formRadioGroupBox input:checked ~ label .radioIcon::after {
  background: var(--primaryColor);
  opacity: 1;
  transform: scale(1);
}
.profileFormPopup .profileFormPopupBody form .formGroup .formRadioGroup .formRadioGroupBox label {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formRadioGroup .formRadioGroupBox label:hover .radioIcon::after {
  opacity: 1;
  transition: 0.2s;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formRadioGroup .formRadioGroupBox label .radioIcon {
  width: 20px;
  height: 20px;
  border: 2px solid #aaa;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formRadioGroup .formRadioGroupBox label .radioIcon::after {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background: #ddd;
  opacity: 0;
  transition: 0.2s;
  transform: scale(0);
}
.profileFormPopup .profileFormPopupBody form .formGroup .formRadioGroup .formRadioGroupBox label .radioText {
  font-size: 14px;
  font-weight: 400;
  width: calc(100% - 30px);
}
.profileFormPopup .profileFormPopupBody form .formGroup .formRadioGroup .radioGroup-Col-3 {
  min-width: calc(33.3333333333% - 27px);
}
.profileFormPopup .profileFormPopupBody form .formGroup .formRadioGroupButton {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  -moz-column-gap: 20px;
       column-gap: 20px;
  margin-top: 10px;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formRadioGroupButton .formRadioGroupBoxButton input {
  display: none;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formRadioGroupButton .formRadioGroupBoxButton input:checked ~ label {
  background: var(--secondaryColor);
  color: white;
  border-color: var(--secondaryColor);
}
.profileFormPopup .profileFormPopupBody form .formGroup .formRadioGroupButton .formRadioGroupBoxButton label {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  padding: 6px 20px;
  border: 1px solid #aaa;
  border-radius: 50px;
  transition: 0.3s;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formRadioGroupButton .formRadioGroupBoxButton label:hover {
  transition: 0.3s;
  border-color: var(--secondaryColor);
  color: var(--secondaryColor);
}
.profileFormPopup .profileFormPopupBody form .formGroup .formGroupDorpdownList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 40px;
       column-gap: 40px;
  row-gap: 20px;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formGroupDorpdownList .formGroupDorpdownListBox {
  width: 100%;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formGroupDorpdownList .formGroupDorpdownListBox .customSelect {
  margin-top: 10px;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formGroupDorpdownList .formGroupDorpdownListBox .css-1cfo1cf {
  margin: 0;
  padding: 0;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formGroupDorpdownList .formGroupDorpdownListBox .css-13cymwt-control {
  border: 1px solid #aaa;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formGroupDorpdownList .formGroupDorpdownListBox .css-19bb58m {
  margin: 0;
  padding: 0;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formGroupDorpdownList .formGroupDorpdownListBox .css-hlgwow {
  padding: 0px 18px;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formGroupDorpdownList .formGroupDorpdownListBox .css-hlgwow input {
  height: 40px;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formGroupDorpdownList .formGroupDorpdownListBox .css-t3ipsp-control {
  outline: none !important;
  border: 1px solid var(--primaryColor);
  box-shadow: none !important;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formGroupDorpdownList .formGroupDorpdownListBox .css-t3ipsp-control:hover {
  border-color: var(--primaryColor) !important;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formGroupDorpdownList .formGroupDorpdownListBox .css-t3ipsp-control:focus {
  border-color: var(--primaryColor) !important;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formGroupDorpdownList .formGroupDorpdownListBox .css-1u9des2-indicatorSeparator {
  display: none;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formTable {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formTable .formTableHead {
  width: 100%;
  display: flex;
  display: none;
}
@media (max-width: 1250px) {
  .profileFormPopup .profileFormPopupBody form .formGroup .formTable .formTableHead {
    display: none;
  }
}
.profileFormPopup .profileFormPopupBody form .formGroup .formTable .formTableHead .formTableHeadBox {
  width: 100%;
  border-left: 1px solid #aaa;
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  padding: 5px;
  background: #cdf2ff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-align: center;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formTable .formTableHead .formTableHeadBox:nth-of-type(1) {
  border-radius: 5px 0px 0px 0px;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formTable .formTableHead .formTableHeadBox:nth-last-of-type(1) {
  border-radius: 0px 5px 0px 0px;
  border-right: 1px solid #aaa;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formTable .formTableBody {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
@media (max-width: 1250px) {
  .profileFormPopup .profileFormPopupBody form .formGroup .formTable .formTableBody {
    gap: 15px;
  }
}
.profileFormPopup .profileFormPopupBody form .formGroup .formTable .formTableBody .formTableBodyRow {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #aaa;
}
@media (max-width: 1250px) {
  .profileFormPopup .profileFormPopupBody form .formGroup .formTable .formTableBody .formTableBodyRow {
    flex-direction: column;
    border-top: 1px solid #aaa;
  }
}
.profileFormPopup .profileFormPopupBody form .formGroup .formTable .formTableBody .formTableBodyRow:nth-last-of-type(1) .formTableBodyBox {
  border-radius: 0px 0px 5px 5px;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formTable .formTableBody .formTableBodyRow .formTableBodyBox {
  width: 100%;
  border-bottom: 1px solid #aaa;
  border-left: 1px solid #aaa;
  padding: 5px;
  border-right: 1px solid #aaa;
  padding: 10px;
}
@media (max-width: 1250px) {
  .profileFormPopup .profileFormPopupBody form .formGroup .formTable .formTableBody .formTableBodyRow .formTableBodyBox {
    border-right: 1px solid #aaa;
    padding: 10px;
  }
}
.profileFormPopup .profileFormPopupBody form .formGroup .formTable .formTableBody .formTableBodyRow .formTableBodyBox:nth-last-of-type(1) {
  border-right: 1px solid #aaa;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formTable .formTableBody .formTableBodyRow .formTableBodyBox .formTableLabel {
  display: none;
  display: block;
  padding: 5px 0px;
}
@media (max-width: 1250px) {
  .profileFormPopup .profileFormPopupBody form .formGroup .formTable .formTableBody .formTableBodyRow .formTableBodyBox .formTableLabel {
    display: block;
    padding: 5px 0px;
  }
}
.profileFormPopup .profileFormPopupBody form .formGroup .formTable .formTableBody .formTableBodyRow .formTableBodyBox input {
  margin-top: 0;
  height: 35px;
  padding: 0px 10px;
  font-size: 16px;
  margin-top: 5px;
}
@media (max-width: 1250px) {
  .profileFormPopup .profileFormPopupBody form .formGroup .formTable .formTableBody .formTableBodyRow .formTableBodyBox input {
    margin-top: 5px;
  }
}
.profileFormPopup .profileFormPopupBody form .formGroup .formTable .formTableBody .formTableBodyRow .formTableBodyBox .MuiStack-root {
  padding: 0;
  overflow: inherit;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formTable .formTableBody .formTableBodyRow .formTableBodyBox .addformTableBodyRowBtn {
  background: #eafaea;
  color: #008d00;
  width: -moz-fit-content;
  width: fit-content;
  height: 35px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: 0.3s;
  border: none;
  outline: none;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formTable .formTableBody .formTableBodyRow .formTableBodyBox .addformTableBodyRowBtn:hover {
  transition: 0.3s;
  background: none;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formTable .formTableBody .formTableBodyRow .formTableBodyBox .deleteformTableBodyRowBtn {
  background: #faeaea;
  color: #ff0000;
  width: -moz-fit-content;
  width: fit-content;
  height: 35px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  border: none;
  outline: none;
  transition: 0.3s;
}
.profileFormPopup .profileFormPopupBody form .formGroup .formTable .formTableBody .formTableBodyRow .formTableBodyBox .deleteformTableBodyRowBtn:hover {
  transition: 0.3s;
  background: none;
}
.profileFormPopup .profileFormPopupBody form .MuiFormControl-root {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}
.profileFormPopup .profileFormPopupBody form .MuiFormControl-root input {
  margin-top: 0;
  border: none;
}
.profileFormPopup .profileFormPopupBody form .MuiFormControl-root .MuiOutlinedInput-notchedOutline {
  border-color: #aaa;
}
.profileFormPopup .profileFormPopupBody form .col-1 {
  width: 100%;
}
.profileFormPopup .profileFormPopupBody form .col-1 .formGroupDorpdownList .formGroupDorpdownListBox {
  width: calc(50% - 20px);
}
@media (max-width: 550px) {
  .profileFormPopup .profileFormPopupBody form .col-1 .formGroupDorpdownList .formGroupDorpdownListBox {
    width: 100%;
  }
}
.profileFormPopup .profileFormPopupBody form .col-1 .formGroupDorpdownList .dorpdownList-Col-3 {
  width: calc(50% - 20px);
}
@media (max-width: 550px) {
  .profileFormPopup .profileFormPopupBody form .col-1 .formGroupDorpdownList .dorpdownList-Col-3 {
    width: 100%;
  }
}
.profileFormPopup .profileFormPopupBody form .uploadResumeBox {
  width: 100%;
  border: 2px dashed #ebdeba;
  padding: 50px;
  border-radius: 10px;
  background: #fffbf0;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 480px) {
  .profileFormPopup .profileFormPopupBody form .uploadResumeBox {
    padding: 20px;
  }
}
.profileFormPopup .profileFormPopupBody form .uploadResumeBox:hover {
  transition: 0.3s;
  background: #fff9da;
}
.profileFormPopup .profileFormPopupBody form .uploadResumeAlert {
  width: 100%;
  padding: 10px 20px;
  background: #FFD5CD;
  font-size: 14px;
  line-height: 1.5;
  margin-top: -20px;
  border-radius: 3px;
}
@media (max-width: 1024px) {
  .profileFormPopup .profileFormPopupBody form .uploadResumeAlert {
    font-size: 12px;
    padding: 10px;
  }
}
.profileFormPopup .profileFormPopupBody form .resumeList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: -20px;
}
.profileFormPopup .profileFormPopupBody form .resumeList .resumeListBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border: 1px solid #ccc;
  padding: 10px 20px;
  border-radius: 10px;
}
.profileFormPopup .profileFormPopupBody form .resumeList .resumeListBox .resumeListBoxCol1 {
  display: flex;
  flex-direction: column;
}
.profileFormPopup .profileFormPopupBody form .resumeList .resumeListBox .resumeListBoxCol1 p, .profileFormPopup .profileFormPopupBody form .resumeList .resumeListBox .resumeListBoxCol1 a {
  font-size: 14px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%;
  font-weight: 500;
}
.profileFormPopup .profileFormPopupBody form .resumeList .resumeListBox .resumeListBoxCol2 {
  display: flex;
  align-items: center;
  gap: 15px;
}
.profileFormPopup .profileFormPopupBody form .resumeList .resumeListBox .resumeListBoxCol2 .resumeListbtn {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  background: var(--bgColor);
  transition: 0.3s;
  color: var(--secondaryColor);
}
.profileFormPopup .profileFormPopupBody form .resumeList .resumeListBox .resumeListBoxCol2 .resumeListbtn:hover {
  transition: 0.3s;
  background: #eee;
}
.profileFormPopup .profileFormPopupBody form .resumeList .resumeListBox .resumeListBoxCol2 .resumeListbtn svg {
  width: 15px;
  height: 15px;
}
.profileFormPopup .profileFormPopupBody form .eductionForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding-bottom: 20px;
}
.profileFormPopup .profileFormPopupBody form .eductionForm .eductionFormBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.profileFormPopup .profileFormPopupBody form .eductionForm .eductionFormBox:nth-last-of-type(1)::after {
  display: none;
}
.profileFormPopup .profileFormPopupBody form .eductionForm .eductionFormBox::after {
  position: absolute;
  bottom: -30px;
  width: 100%;
  height: 1px;
  background: #eee;
  content: "";
  left: 0;
}
.profileFormPopup .profileFormPopupBody form .eductionForm .eductionFormBox .eductionFormBoxHead {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.profileFormPopup .profileFormPopupBody form .eductionForm .eductionFormBox .eductionFormBoxHead h3 {
  font-size: 16px;
  font-weight: 600;
  color: var(--secondaryColor);
}
.profileFormPopup .profileFormPopupBody form .eductionForm .eductionFormBox .eductionFormBoxHead svg {
  width: 18px;
  height: 18px;
  color: var(--secondaryColor);
}
.profileFormPopup .profileFormPopupBody form .eductionForm .eductionFormBox .eductionFormBoxBody {
  width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
  -moz-column-gap: 40px;
       column-gap: 40px;
  row-gap: 30px;
  display: flex;
}
.profileFormPopup .profileFormPopupBody form .eductionForm .eductionFormBox .eductionFormBoxBodyActive {
  display: flex;
}
.profileFormPopup .profileFormPopupBody form .customDropdown {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}
.profileFormPopup .profileFormPopupBody form .customDropdown .customDropdownHead {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 10px;
}
.profileFormPopup .profileFormPopupBody form .customDropdown .customDropdownHead input {
  margin-top: 0;
}
.profileFormPopup .profileFormPopupBody form .customDropdown .customDropdownHead input::-webkit-search-cancel-button {
  position: relative;
  right: 20px;
}
.profileFormPopup .profileFormPopupBody form .customDropdown .customDropdownHead input::-moz-placeholder {
  color: #808080;
}
.profileFormPopup .profileFormPopupBody form .customDropdown .customDropdownHead input::placeholder {
  color: #808080;
}
.profileFormPopup .profileFormPopupBody form .customDropdown .customDropdownHead input:focus ~ .customDropdownHeadcon svg {
  color: var(--primaryColor);
}
.profileFormPopup .profileFormPopupBody form .customDropdown .customDropdownHead .customDropdownHeadcon {
  position: absolute;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 10px;
}
.profileFormPopup .profileFormPopupBody form .customDropdown .customDropdownHead .customDropdownHeadcon svg {
  width: 15px;
  height: 15px;
  color: #cccccc;
}
.profileFormPopup .profileFormPopupBody form .customDropdown .dropdownMenu {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  border: 1px solid #ddd;
  background-color: white;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.09);
  z-index: 10;
  max-height: 150px;
  overflow-y: auto;
  list-style: none;
  padding: 0px;
  margin: 0;
  border-radius: 5px;
}
.profileFormPopup .profileFormPopupBody form .customDropdown .dropdownMenu li {
  padding: 8px 12px;
  cursor: pointer;
}
.profileFormPopup .profileFormPopupBody form .customDropdown .dropdownMenu li:nth-of-type(1) {
  margin-top: 5px;
}
.profileFormPopup .profileFormPopupBody form .customDropdown .dropdownMenu li:nth-last-of-type(1) {
  margin-bottom: 5px;
}
.profileFormPopup .profileFormPopupBody form .customDropdown .dropdownMenu li:hover {
  background-color: #eee;
}
.profileFormPopup .profileFormPopupBody form .customDropdown .dropdownMenu .selected {
  background-color: #d2e2ff !important;
}
.profileFormPopup .profileFormPopupBody form .customDropdown .dropdownMenu li:hover, .profileFormPopup .profileFormPopupBody form .customDropdown .dropdownMenu li.selected {
  background-color: #eee;
}
.profileFormPopup .profileFormPopupBody form .personalDetailsForm {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 40px;
       column-gap: 40px;
  row-gap: 30px;
}
.profileFormPopup .profileFormPopupBody form .languageDetailsForm {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.profileFormPopup .profileFormPopupBody form .languageDetailsForm .languageDetailsFormBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 40px;
       column-gap: 40px;
  row-gap: 30px;
}
.profileFormPopup .profileFormPopupBody form .languageDetailsForm .languageDetailsFormBox .languageDetailsFormBoxFooter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}
.profileFormPopup .profileFormPopupBody form .languageDetailsForm .languageDetailsFormBox .languageDetailsFormBoxFooter .languageDetailsFormBoxFooterCol1 {
  display: flex;
}
.profileFormPopup .profileFormPopupBody form .languageDetailsForm .languageDetailsFormBox .languageDetailsFormBoxFooter .languageDetailsFormBoxFooterCol1 .formGroupCheckboxArea {
  width: 100%;
}
.profileFormPopup .profileFormPopupBody form .languageDetailsForm .languageDetailsFormBox .languageDetailsFormBoxFooter .languageDetailsFormBoxFooterCol1 .formGroupCheckboxArea ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 25px;
  -moz-column-gap: 60px;
       column-gap: 60px;
}
.profileFormPopup .profileFormPopupBody form .languageDetailsForm .languageDetailsFormBox .languageDetailsFormBoxFooter .languageDetailsFormBoxFooterCol1 .formGroupCheckboxArea ul li {
  list-style: none;
}
.profileFormPopup .profileFormPopupBody form .languageDetailsForm .languageDetailsFormBox .languageDetailsFormBoxFooter .languageDetailsFormBoxFooterCol1 .formGroupCheckboxArea ul li input {
  display: none;
}
.profileFormPopup .profileFormPopupBody form .languageDetailsForm .languageDetailsFormBox .languageDetailsFormBoxFooter .languageDetailsFormBoxFooterCol1 .formGroupCheckboxArea ul li input:checked ~ label:hover .checkboxIcon::after {
  border-color: #fff;
}
.profileFormPopup .profileFormPopupBody form .languageDetailsForm .languageDetailsFormBox .languageDetailsFormBoxFooter .languageDetailsFormBoxFooterCol1 .formGroupCheckboxArea ul li input:checked ~ label .checkboxIcon {
  border-color: var(--primaryColor);
  background: var(--primaryColor);
}
.profileFormPopup .profileFormPopupBody form .languageDetailsForm .languageDetailsFormBox .languageDetailsFormBoxFooter .languageDetailsFormBoxFooterCol1 .formGroupCheckboxArea ul li input:checked ~ label .checkboxIcon::after {
  opacity: 1;
}
.profileFormPopup .profileFormPopupBody form .languageDetailsForm .languageDetailsFormBox .languageDetailsFormBoxFooter .languageDetailsFormBoxFooterCol1 .formGroupCheckboxArea ul li label {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.profileFormPopup .profileFormPopupBody form .languageDetailsForm .languageDetailsFormBox .languageDetailsFormBoxFooter .languageDetailsFormBoxFooterCol1 .formGroupCheckboxArea ul li label:hover .checkboxIcon::after {
  opacity: 1;
  border-color: #ddd;
}
.profileFormPopup .profileFormPopupBody form .languageDetailsForm .languageDetailsFormBox .languageDetailsFormBoxFooter .languageDetailsFormBoxFooterCol1 .formGroupCheckboxArea ul li label .checkboxIcon {
  width: 20px;
  height: 20px;
  border: 2px solid #aaa;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.profileFormPopup .profileFormPopupBody form .languageDetailsForm .languageDetailsFormBox .languageDetailsFormBoxFooter .languageDetailsFormBoxFooterCol1 .formGroupCheckboxArea ul li label .checkboxIcon::after {
  position: absolute;
  content: "";
  width: 5px;
  height: 9px;
  opacity: 0;
  transition: 0.2s;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(45deg);
  top: 1px;
}
.profileFormPopup .profileFormPopupBody form .languageDetailsForm .languageDetailsFormBox .languageDetailsFormBoxFooter .languageDetailsFormBoxFooterCol1 .formGroupCheckboxArea ul li label .checkboxText {
  font-size: 16px;
}
.profileFormPopup .profileFormPopupBody form .languageDetailsForm .languageDetailsFormBox .languageDetailsFormBoxFooter .languageDetailsFormBoxFooterCol2 {
  display: flex;
}
.profileFormPopup .profileFormPopupBody form .languageDetailsForm .languageDetailsFormBox .languageDetailsFormBoxFooter .languageDetailsFormBoxFooterCol2 .deleteLanguageBtn {
  font-size: 16px;
  font-weight: 600;
  color: red;
  cursor: pointer;
}
.profileFormPopup .profileFormPopupBody form .languageDetailsForm .addLanguageDetailsFormBoxArea {
  width: 100%;
  display: flex;
}
.profileFormPopup .profileFormPopupBody form .languageDetailsForm .addLanguageDetailsFormBoxArea .addLanguageBtn {
  font-size: 16px;
  font-weight: 600;
  color: rgb(0, 141, 0);
  cursor: pointer;
  background: rgb(234, 250, 234);
  padding: 12px 20px;
  border-radius: 10px;
  transition: 0.3s;
}
.profileFormPopup .profileFormPopupBody form .languageDetailsForm .addLanguageDetailsFormBoxArea .addLanguageBtn:hover {
  background: white;
  transition: 0.3s;
}
.profileFormPopup .profileFormPopupBody form .profileImageBoxMain {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 40px;
  margin-top: 10px;
}
@media (max-width: 550px) {
  .profileFormPopup .profileFormPopupBody form .profileImageBoxMain {
    flex-wrap: wrap;
  }
}
.profileFormPopup .profileFormPopupBody form .profileImageBoxMain .profileImageBoxMainCol1 {
  width: calc(50% - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 550px) {
  .profileFormPopup .profileFormPopupBody form .profileImageBoxMain .profileImageBoxMainCol1 {
    width: 100%;
  }
}
.profileFormPopup .profileFormPopupBody form .profileImageBoxMain .profileImageBoxMainCol1 .profileImageBox {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  border: 2px dashed #ddd;
  background: var(--bgColor);
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  padding: 20px;
  position: relative;
}
@media (max-width: 550px) {
  .profileFormPopup .profileFormPopupBody form .profileImageBoxMain .profileImageBoxMainCol1 .profileImageBox {
    height: 150px;
  }
}
.profileFormPopup .profileFormPopupBody form .profileImageBoxMain .profileImageBoxMainCol1 .profileImageBox:hover {
  border-color: #00a545;
  transition: 0.3s;
}
.profileFormPopup .profileFormPopupBody form .profileImageBoxMain .profileImageBoxMainCol1 .profileImageBox .profileImageBoxCanvas {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.profileFormPopup .profileFormPopupBody form .profileImageBoxMain .profileImageBoxMainCol1 .profileImageBox p {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  line-height: 1.5;
}
@media (max-width: 550px) {
  .profileFormPopup .profileFormPopupBody form .profileImageBoxMain .profileImageBoxMainCol1 .profileImageBox p {
    font-size: 14px;
  }
}
.profileFormPopup .profileFormPopupBody form .profileImageBoxMain .profileImageBoxMainCol1 .profileImageBoxBtnArea {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
  gap: 15px;
}
.profileFormPopup .profileFormPopupBody form .profileImageBoxMain .profileImageBoxMainCol1 .profileImageBoxBtnArea .profileImageResetBtn {
  height: 35px;
  width: 35px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eee;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.3s;
  gap: 10px;
  transform: scaleX(-1);
}
.profileFormPopup .profileFormPopupBody form .profileImageBoxMain .profileImageBoxMainCol1 .profileImageBoxBtnArea .profileImageResetBtn svg {
  color: black;
  width: 18px;
  height: 18px;
}
.profileFormPopup .profileFormPopupBody form .profileImageBoxMain .profileImageBoxMainCol1 .profileImageBoxBtnArea .profileImageResetBtn:hover {
  transition: 0.3s;
  opacity: 0.7;
}
.profileFormPopup .profileFormPopupBody form .profileImageBoxMain .profileImageBoxMainCol1 .profileImageBoxBtnArea .profileImageSaveBtn {
  height: 35px;
  padding: 0px 20px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--secondaryColor);
  color: white;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.3s;
  gap: 10px;
}
.profileFormPopup .profileFormPopupBody form .profileImageBoxMain .profileImageBoxMainCol1 .profileImageBoxBtnArea .profileImageSaveBtn svg {
  color: white;
  width: 18px;
  height: 18px;
}
.profileFormPopup .profileFormPopupBody form .profileImageBoxMain .profileImageBoxMainCol1 .profileImageBoxBtnArea .profileImageSaveBtn:hover {
  transition: 0.3s;
  opacity: 0.7;
}
.profileFormPopup .profileFormPopupBody form .profileImageBoxMain .profileImageBoxMainCol2 {
  width: calc(50% - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 550px) {
  .profileFormPopup .profileFormPopupBody form .profileImageBoxMain .profileImageBoxMainCol2 {
    width: 100%;
  }
}
.profileFormPopup .profileFormPopupBody form .profileImageBoxMain .profileImageBoxMainCol2 .profileImageView {
  width: 100%;
  height: 250px;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: var(--bgColor);
}
@media (max-width: 550px) {
  .profileFormPopup .profileFormPopupBody form .profileImageBoxMain .profileImageBoxMainCol2 .profileImageView {
    height: 150px;
  }
}
.profileFormPopup .profileFormPopupBody form .profileImageBoxMain .profileImageBoxMainCol2 .profileImageView .profileImageViewBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.profileFormPopup .profileFormPopupBody form .profileImageBoxMain .profileImageBoxMainCol2 .profileImageView h2 {
  text-align: center;
}
.profileFormPopup .profileFormPopupBody form .profileImageBoxMain .profileImageBoxMainCol2 .profileImageView img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 2px solid #eee;
  margin-top: 10px;
}
@media (max-width: 550px) {
  .profileFormPopup .profileFormPopupBody form .profileImageBoxMain .profileImageBoxMainCol2 .profileImageView img {
    height: 60px;
    width: 60px;
  }
}
.profileFormPopup .profileFormPopupBody form .line {
  width: 100%;
  height: 1px;
  background: #eee;
}
@media (max-width: 1024px) {
  .profileFormPopup .profileFormPopupBody form .mobDisplayNone {
    display: none;
  }
}
.profileFormPopup .profileFormPopupFooter {
  width: 100%;
  padding: 15px 50px;
  border-top: 1px solid #eee;
}
@media (max-width: 480px) {
  .profileFormPopup .profileFormPopupFooter {
    padding: 12px 15px;
  }
}
.profileFormPopup .profileFormPopupFooter .formBtnArea {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
}
.profileFormPopup .profileFormPopupFooter .formBtnArea .closeProfileFormPopup {
  padding: 0px 25px;
  height: 40px;
  background: var(--bgColor);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  transition: 0.3s;
}
.profileFormPopup .profileFormPopupFooter .formBtnArea .closeProfileFormPopup:hover {
  transition: 0.3s;
  opacity: 0.7;
}
.profileFormPopup .profileFormPopupFooter .formBtnArea .submitProfileFormPopup {
  padding: 0px 25px;
  height: 40px;
  background: var(--primaryColor);
  color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  border: none;
  outline: none;
  transition: 0.3s;
}
.profileFormPopup .profileFormPopupFooter .formBtnArea .submitProfileFormPopup:hover {
  transition: 0.3s;
  opacity: 0.7;
}

.loader {
  display: flex;
  align-items: center;
  gap: 5px;
}
.loader span {
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background: white;
  animation: loadingBtnAnimation 0.8s infinite linear;
}
.loader span:nth-of-type(2) {
  animation-delay: 0.1s;
}
.loader span:nth-of-type(3) {
  animation-delay: 0.2s;
}
.loader span:nth-of-type(4) {
  animation-delay: 0.3s;
}
@keyframes loadingBtnAnimation {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  50% {
    transform: translateY(5px);
    opacity: 0.4;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.pageNotFound {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0px;
  background-color: #f3f3f3;
  min-height: 100vh;
  text-align: center;
  color: #333;
  width: 100%;
}
@media (max-width: 550px) {
  .pageNotFound {
    margin-top: 60px;
  }
}
.pageNotFound h1 {
  font-size: 3em;
  color: #faca2e;
  margin: 0;
  margin-top: 20px;
}
.pageNotFound p {
  margin: 0;
  font-size: 16px;
  margin-top: 20px;
}
.pageNotFound button {
  padding: 10px 30px;
  background: #000;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 10px;
  font-size: 16px;
  margin-top: 40px;
  transition: 0.3s;
}
.pageNotFound button:hover {
  transition: 0.3s;
  opacity: 0.7;
}
.pageNotFound .puzzleBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pageNotFound .puzzleGrid {
  display: grid;
  grid-template-columns: repeat(3, 100px);
  gap: 10px;
  margin-top: 20px;
}
.pageNotFound .puzzleTile {
  width: 100px;
  height: 100px;
  background-color: #f3f3f3;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}
.pageNotFound .empty {
  background-color: #CADABF;
  cursor: default;
}
.pageNotFound .message {
  padding: 20px 40px;
  border-radius: 10px;
  background: #FC8F54;
  color: white;
  margin: 0 auto;
  /* margin-top: 20px; */
  width: -moz-fit-content;
  width: fit-content;
  position: fixed;
  transform: translateX(-50%);
  left: 50%;
  top: 100px;
  opacity: 1;
}
@media (max-width: 480px) {
  .pageNotFound .message {
    width: 90%;
  }
}
.pageNotFound .messageActive {
  transition: 0.3s;
  top: 50px;
  opacity: 1;
}
.pageNotFound .eyes {
  display: flex;
  justify-content: center;
  gap: 2px;
}
.pageNotFound .eye {
  width: 80px;
  height: 80px;
  background-color: #faca2e;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.pageNotFound .pupil {
  width: 30px;
  height: 30px;
  background-color: #050505;
  border-radius: 50%;
  animation: movePupil 2s infinite ease-in-out;
  transform-origin: center center;
}
@keyframes movePupil {
  0%, 100% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-10px, -10px);
  }
  50% {
    transform: translate(10px, 10px);
  }
  75% {
    transform: translate(-10px, 10px);
  }
}/*# sourceMappingURL=main.css.map */
// font
// @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
  

// color
:root {
    --primaryColor: #525CEB; // 406098
    --secondaryTextColor: #494949;
    --bgColor: #f6f8fa;
    --secondaryColor: #0077ff;
}

// A555EC
body {
    // font-family: "Inter", sans-serif;
    font-family: "Poppins", sans-serif;
    background: var(--bgColor);
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #c9cdd735;
    border: 1px solid #8e929c20;
    border-radius: 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #c9cdd7;
    border-radius: 50px;
    &:hover {
        background: #8d919db1;
        cursor: grab;
    }
}

.container {
    width: calc(100% - 550px);
    margin: 0 auto;
    @media(max-width:1800px){
        width: calc(100% - 200px);
    }
    @media(max-width:1024px){
        width: calc(100% - 100px);
    }
    @media(max-width:520px){
        width: calc(100% - 32px);
    }
}

a,
article,
button,
div,
span,
i,
b,
h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
ul,
li,
select,
label,
textarea {
    -webkit-touch-callout: none;
    // -webkit-user-select: none;
    // -khtml-user-select: none;
    // -moz-user-select: none;
    // -ms-user-select: none;
    // user-select: none;
    -webkit-tap-highlight-color: transparent;
    // font-family: "Inter", sans-serif;
    font-family: "Poppins", sans-serif;
}

input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    // font-family: "Inter", sans-serif;
    font-family: "Poppins", sans-serif;
}

input {
     -webkit-tap-highlight-color: transparent;
}

// input[type="date"]::-webkit-inner-spin-button,
// input[type="date"]::-webkit-calendar-picker-indicator {
//     display: none;
//     -webkit-appearance: none;
// }
::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
    color: transparent;
}

video::-internal-media-controls-overlay-cast-button {
    display: none;
}

textarea {
    resize: none;
    // font-family: "Inter", sans-serif;
    font-family: "Poppins", sans-serif;
}

::selection {
    color: white;
    background: #1d1d1d;
}
p{
    // font-family: "Inter", sans-serif;
    font-family: "Poppins", sans-serif;
}

// pageLoading
.pageLoading{
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: white;
    z-index: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    .spinLoader{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 4px solid #eee;
        border-top-color: var(--primaryColor);
        animation: loadingSpin 1s linear infinite;
        @keyframes loadingSpin {
            100%{
                transform: rotate(1turn);
            }
        }
    }
}

// shimmer
.shimmer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 100;
    background: rgba(0, 0, 0, 0.822);
    backdrop-filter: blur(5px);
    display: none;
}

// loginPopup
.loginPopup{
    position: fixed;
    z-index: 210;
    width: 550px;
    background: white;
    border-radius: 15px;
    transform: translate(-50%, -50%);
    top: 50%;
    overflow: hidden;
    left: 50%;
    @media(max-width:570px){
        width: 90%;
    }
    .closeloginPopup{
        position: absolute;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.409);
        right: 20px;
        top: 20px;
        cursor: pointer;
        transition: .3s;
        &:hover{
            transition: .3s;
            background: white;
        }
        svg{
            width: 12px;
            height: 12px;
        }
    }
    .loginPopupHead{
        width: 100%;
        padding: 40px;
        // background-image: linear-gradient(to right, #a18cd1 0%, #fbc2eb 100%);
        background-image: linear-gradient(-225deg, #00b8b5 0%, #85a8f4 48%, #d098ff 100%);
        @media(max-width:520px){
            padding: 40px 20px;
        }
        h2{
            color: white;
        }
        p{
            font-size: 18px;
            line-height: 1.4;
            margin-top: 10px;
            color: white;
            @media(max-width:520px){
                font-size: 16px;
            }
        }
    }
    .loginPopupBody{
        width: 100%;
        padding: 40px;
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        @media(max-width:520px){
            padding: 40px 20px;
        }
        @media(max-width:480px){
            padding: 20px;
        }
        p{
            font-size: 18px;
            line-height: 1.4;
            margin-bottom: 10px;
            color: var(--secondaryTextColor);
        }
        a{
            text-decoration: none;
            width: calc(100% / 2 - 10px);
            height: 50px;
            border: 1px solid var(--primaryColor);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            font-size: 20px;
            gap: 10px;
            transition: .3s;
            @media(max-width:480px){
                width: 100%;
            }
            svg{
                width: 18px;
                height: 18px;
            }
        }
        .loginBtn{
            background: var(--primaryColor);
            color: white;
            &:hover{
                transition: .3s;
                background: white;
                color: var(--primaryColor);
            }
        }
        .registerBtn{
            color: var(--primaryColor);
            &:hover{
                transition: .3s;
                background: var(--primaryColor);
                color: white;
            }
        }
    }
}

// nav
nav{
    width: 100%;
    background: white;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    .navMain{
        width: 100%;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media(max-width:520px){
            height: 60px;
        }
        .navCol1{
            display: flex;
            align-items: center;
            .navLogo{
                height: 60px;
                width: 250px;
                text-decoration: none;
                display: flex;
                align-items: center;
                @media(max-width:520px){
                    height: 50px;
                    width: 200px;
                }
                h2{
                    color: black;
                    font-size: 22px;
                    font-weight: 700;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .navLink{
                display: flex;
                margin-left: 100px;
                @media(max-width:1800px){
                    margin-left: 60px;
                }
                @media(max-width:1360px){
                    display: none;
                }
                ul{
                    display: flex;
                    align-items: center;
                    gap: 50px;
                    @media(max-width:1800px){
                        gap: 40px;
                    }
                    li{
                        list-style: none;
                        a{
                            text-decoration: none;
                            color: rgb(26, 26, 26);
                            font-size: 16px;
                            padding: 10px 0px;
                            position: relative;
                            @media(max-width:1800px){
                                font-size: 14px;
                            }
                            &:hover{
                                &::after{
                                    display: block;
                                }
                                &::before{
                                    display: block;
                                }
                            }
                            &::after{
                                position: absolute;
                                content: '';
                                width: 5px;
                                height: 5px;
                                border-radius: 5px;
                                background: var(--primaryColor);
                                bottom: 0;
                                left: calc(50% - 5px);
                                transform: translateX(-50%);
                                display: none;
                            }
                            &::before{
                                position: absolute;
                                content: '';
                                width: 5px;
                                height: 5px;
                                border-radius: 5px;
                                background: var(--primaryColor);
                                bottom: 0;
                                left: calc(50% + 5px);
                                transform: translateX(-50%);
                                display: none;
                            }
                        }
                        .navLinkActive{
                            color: var(--primaryColor);
                            font-weight: 600;
                            &::after{
                                display: block;
                            }
                            &::before{
                                display: block;
                            }
                        }
                    }
                }
            }
        }
        .navCol2{
            display: flex;
            align-items: center;
            justify-content: center;
            .navSearch{
                width: 300px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 40px;
                @media(max-width:850px){
                    width: 40px;
                }
                @media(max-width:600px){
                    margin-right: 20px;
                }
                @media(max-width:520px){
                    margin-right: 15px;
                }
                .navSearchBox{
                    width: 100%;
                    height: 40px;
                    background: var(--bgColor);
                    border-radius: 50px;
                    display: flex;
                    align-items: center;
                    padding: 0px 20px;
                    justify-content: space-between;
                    cursor: pointer;
                    transition: .3s;
                    border: 1px solid transparent;
                    @media(max-width:850px){
                        padding: 0;
                        justify-content: center;
                    }
                    @media(max-width:480px){
                        background: none;
                    }
                    &:hover{
                        transition: .3s;
                        border: 1px solid var(--primaryColor);
                    }
                    p{
                        font-size: 14px;
                        color: var(--secondaryTextColor);
                        @media(max-width:850px){
                            display: none;
                        }
                    }
                    svg{
                        width: 18px;
                        height: 18px;
                    }
                }
            }
            .navLogin{
                display: flex;
                .navLoginBtn{
                    color: var(--primaryColor);
                    padding: 0px 26px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-decoration: none;
                    font-size: 16px;
                    border-radius: 10px;
                    cursor: pointer;
                    gap: 10px;
                    border: 1px solid var(--primaryColor);
                    transition: .3s;
                    display: none;
                    &:hover{
                        transition: .3s;
                        background: var(--primaryColor);
                        color: white;
                    }
                    svg{
                        width: 18px;
                        height: 18px;
                    }
                }
                .navProfileBtn{
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    background: var(--bgColor);
                    height: 50px;
                    padding: 0px 10px 0px 15px;
                    border: 1px solid #f4f3f3;
                    border-radius: 10px;
                    cursor: pointer;
                    transition: .3s;
                    @media(max-width:480px){
                        padding: 0;
                        height: auto;
                        border: none;
                        background: none;
                    }
                    &:hover{
                        transition: .3s;
                        border-color: var(--primaryColor);
                    }
                    .navProfileNavBar{
                        width: 30px;
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                        @media(max-width:480px){
                            display: none;
                        }
                        span{
                            width: 100%;
                            height: 2px;
                            background: var(--primaryColor);
                        }
                    }
                    .navProfileIcon{
                        width: 35px;
                        height: 35px;
                        border-radius: 50px;
                        background: white;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: var(--primaryColor);
                        @media(max-width:480px){
                            background: var(--primaryColor);
                            color: white;
                        }
                        svg{
                            width: 15px;
                            height: 15px;
                        }
                    }
                }
            }
            .navBtnArea{
                display: flex;
                align-items: center;
                gap: 20px;
                margin-right: 40px;
                @media(max-width:600px){
                    margin-right: 20px;
                }
                @media(max-width:520px){
                    margin-right: 15px;
                }
                .navBtnAreaBox{
                    width: 40px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    background: var(--bgColor);
                    color: black;
                    transition: .3s;
                    border: 1px solid transparent;
                    position: relative;
                    @media(max-width:480px){
                        background: none;
                    }
                    &:hover{
                        transition: .3s;
                        border: 1px solid var(--primaryColor);
                        .navDropDown{
                            display: block;
                        }
                    }
                    .notificationActiveIcon{
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 10px;
                        height: 10px;
                        border-radius: 50px;
                        background: #00c610;
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        @media(max-width:520px){
                            right: 8px;
                            top: 5px;
                        }
                        &::after{
                            position: absolute;
                            content: '';
                            width: 20px;
                            height: 20px;
                            border-radius: 50px;
                            background: #00c6103e;
                            animation: notifyActive 1s infinite linear;
                            transform: scale(0);
                            @keyframes notifyActive {
                                100%{
                                    transform: scale(1);
                                }
                            }
                        }
                    }
                    svg{
                        width: 20px;
                        height: 20px;
                    }
                    .navDropDown{
                        position: absolute;
                        width: 300px;
                        background: white;
                        border-radius: 5px;
                        max-height: 300px;
                        top: 100%;
                        left: 50%;
                        transform: translateX(-50%);
                        border: 1px solid #eee;
                        box-shadow: 5px 5px 20px #0000000d;
                        overflow-y: auto;
                        display: none;
                        @media(max-width:480px){
                            right: -20px;
                            left: inherit;
                            transform: inherit;
                        }
                        ul{
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            li{
                                list-style: none;
                                position: relative;
                                &::after{
                                    position: absolute;
                                    content: '';
                                    width: calc(100% - 40px);
                                    left: 20px;
                                    bottom: 0;
                                    height: 1px;
                                    background: var(--bgColor);
                                }
                                &:nth-last-of-type(1){
                                    &::after{
                                        display: none;
                                    }
                                }
                                a{
                                    text-decoration: none;
                                    width: 100%;
                                    padding: 20px 20px;
                                    display: flex;
                                    flex-direction: column;
                                    transition: .3s;
                                    &:hover{
                                        transition: .3s;
                                        background: var(--bgColor);
                                    }
                                    h2{
                                        font-size: 16px;
                                        font-weight: 500;
                                        color: black;
                                    }
                                    p{
                                        font-size: 12px;
                                        margin-top: 5px;
                                        color: var(--secondaryTextColor);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// navSearchStrip
.navSearchStrip{
    position: fixed;
    width: calc(100% - 20px);
    background: white;
    z-index: 110;
    top: -350px;
    left: 10px;
    border-radius: 5px;
    .closeNavSearchStrip{
        position: absolute;
        right: 30px;
        top: 30px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--bgColor);
        cursor: pointer;
        transition: .3s;
        border-radius: 50px;
        @media(max-width: 600px){
            right: 20px;
            top: 20px;
        }
        &:hover{
            transition: .3s;
            background: #eee;
        }
        svg{
            width: 20px;
            height: 20px;
        }
    }
    .navSearchStripMain{
        width: 100%;
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 50px;
        @media(max-width:1024px){
            height: 250px;
        }
        @media(max-width: 600px){
            margin-top: 20px;
            padding-bottom: 20px;
        }
        form{
            width: 100%;
            display: flex;
            align-items: center;
            background: white;
            border-radius: 10px;
            gap: 10px;
            margin-top: 10px;
            @media(max-width: 600px){
                flex-wrap: wrap;
                gap: 15px;
            }
            .formGroupBox{
                width: calc(100% - 210px);
                // display: flex;
                align-items: center;
                gap: 20px;
                flex-wrap: wrap;
                display: none;
                // @media(max-width: 768px){
                //     display: none;
                // }
                .formGroup{
                    // width: calc(100% / 3 - 14px);
                    // border: 1px solid #eee;
                    width: 100%;
                    input{
                        width: 100%;
                        height: 60px;
                        border: none;
                        outline: none;
                        font-size: 20px;
                        padding: 0px 10px;
                        color: black;
                        @media(max-width:1024px){
                            height: 40px;
                        }
                    }
                }
            }
            .formBtnArea{
                width: 200px;
                @media(max-width: 600px){
                    width: 100%;
                }
                .formSbtBtn{
                    width: 100%;
                    background: var(--primaryColor);
                    color: white;
                    height: 70px;
                    border: none;
                    outline: none;
                    cursor: pointer;
                    border-radius: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 17px;
                    font-size: 22px;
                    transition: .3s;
                    @media(max-width:1024px){
                        height: 55px;
                    }
                    &:hover{
                        transition: .3s;
                        opacity: .7;
                    }
                    svg{
                        width: 22px;
                        height: 22px;
                    }
                }
            }
            .css-13cymwt-control{
                border: 1px solid transparent;
                background: var(--bgColor);
                border-radius: 10px;
            }
            .css-t3ipsp-control{
                outline: none !important;
                border: 1px solid var(--primaryColor);
                box-shadow: none !important;
                &:hover{
                    border-color: var(--primaryColor) !important;
                }
                &:focus{
                    border-color: var(--primaryColor) !important;
                }
            }
            .css-1u9des2-indicatorSeparator{
                display: none;
            }
            .mobileSearchInput{
                display: block;
                width: calc(100% - 210px);
                // @media(max-width: 768px){
                //     display: block;
                // }
                @media(max-width: 600px){
                    width: 100%;
                }
                input{
                    width: 100%;
                    outline: none;
                    font-size: 20px;
                    padding: 0px 10px;
                    color: black;
                    outline: none;
                    border: 1px solid #eee;
                    background: var(--bgColor);
                    height: 70px;
                    border-radius: 10px;
                    @media(max-width:1024px){
                        height: 55px;
                    }
                    @media(max-width:520px){
                        font-size: 16px;
                    }
                    &:focus{
                        border-color: #aaa;
                    }
                }
            }
        }
    }
}

.navSearchStripActive{
    top: 10px;
    transition: .2s;
}

// sidemenu
.sidemenu{
    position: fixed;
    z-index: 100;
    right: -500px;
    top: 0;
    height: 100vh;
    background: white;
    width: 400px;
    @media(max-width:480px){
        width: 100%;
    }
    .closeSidemenu{
        position: absolute;
        right: 20px;
        top: 20px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        color: var(--secondaryTextColor);
        border-radius: 5px;
        background: var(--bgColor);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .3s;
        &:hover{
            transition: .3s;
            background: #eee;
        }
        svg{
            width: 15px;
            height: 15px;
        }
    }
    .sidemenuHead{
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0px 20px;
        height: 90px;
        margin-top: 60px;
        border-bottom: 1px solid #eee;
        .sidemenuHeadLogin{
            width: 100%;
            display: none;
            .sidemenuLoginBtn{
                width: 100%;
                text-decoration: none;
                background: var(--primaryColor);
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 16px 20px;
                border-radius: 3px;
                color: white;
                gap: 15px;
                font-size: 20px;
                transition: .3s;
                &:hover{
                    transition: .3s;
                    opacity: .7;
                }
                svg{
                    width: 20px;
                    height: 20px;
                }
            }
        }
        .sidemenuHeadProfile{
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            align-items: flex-start;
            .sidemenuHeadCol1{
                width: 60px;
                height: 60px;
                .sidemenuProfileIcon{
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    background: var(--bgColor);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #f4f3f3;
                    svg{
                        width: 20px;
                        height: 20px;
                        color: #aaa;
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;

                    }
                }
            }
            .sidemenuHeadCol2{
                width: calc(100% - 70px);
                display: flex;
                flex-direction: column;
                .sidemenuProfileName{
                    font-size: 20px;
                    font-weight: 600;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
                .sidemenuPhone{
                    font-size: 12px;
                    font-weight: 400;
                    margin-top: 3px;
                    color: var(--secondaryTextColor);
                }
                .sidemenuProfileViewBtn{
                    text-decoration: none;
                    color: var(--secondaryColor);
                    font-size: 14px;
                    font-weight: 600;
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    &:hover{
                        text-decoration: underline;
                    }
                    svg{
                        width: 14px;
                        height: 14px;
                    }
                }
            }
        }
    }
    .sidemenuBody{
        width: 100%;
        height: calc(100vh - 150px);
        overflow-y: auto;
        ul{
            width: 100%;
            display: flex;
            flex-direction: column;
            border-top: 10px solid var(--bgColor);
            padding: 10px 0px;
            &:nth-of-type(1){
                border-top: none;
            }
            li{
                list-style: none;
                a{
                    text-decoration: none;
                    padding: 10px 20px;
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    font-size: 14px;
                    color: black;
                    &:hover{
                        background: #f8f6f6;
                    }
                    svg{
                        width: 15px;
                        height: 15px;
                        color: var(--secondaryTextColor);
                    }
                }
                .sidemenuLinkActive{
                    color: var(--secondaryColor);
                    background: #f8f6f6;
                    svg{
                        color: var(--secondaryColor);
                    }
                }
                .logOutBtn{
                    opacity: .5;
                }
            }
        }
    }
}
.sidemenuActive{
    right:0;
    transition: .3s;
}

.mainTitle{
    font-size: 4em;
    font-weight:  400;
    line-height: 1.2;
    @media(max-width:1800px){
        font-size: 3.5em;
    }
    @media(max-width:1024px){
        font-size: 3em;
    }
    @media(max-width:768px){
        font-size: 2.5em;
    }
    @media(max-width:520px){
        font-size: 2.2em;
    }
}

.titleHead{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .titleMoreLink{
        display: flex;
        a{
            text-decoration: none;
            padding: 10px 25px;
            border: 1px solid var(--primaryColor);
            color: var(--primaryColor);
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            background: white;
            border-radius: 10px;
            font-weight: 500;
            transition: .3s;
            &:hover{
                transition: .3s;
                background: var(--primaryColor);
                color: white;
            }
            svg{
                width: 18px;
                height: 18px;
            }
        }
    }
}
.title{
    font-size: 28px;
    font-weight: 600;
    @media(max-width:1024px){
        font-size: 24px;
    }
    @media(max-width:520px){
        font-size: 22px;
    }
}

.subTitle{
    font-size: 18px;
    font-weight: 600;
}

// footerMain
footer{
    width: 100%;
    padding: 100px 0px 20px 0px;
    background: #0d101f;
    @media(max-width:520px){
        padding: 60px 0px 20px 0px;
    }
    .footerMain{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .footerCol1{
            display: flex;
            flex-direction: column;
            @media(max-width:1024px){
                flex: 100%;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                gap: 20px;
            }
            .footerLogo{
                height: 90px;
                width: 220px;
                text-decoration: none;
                display: flex;
                align-items: center;
                background: white;
                padding: 10px;
                border-radius: 3px;
                overflow: hidden;
                @media(max-width:520px){
                    width: 200px;
                    height: 80px;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .footerSocialLinks{
                margin-top: auto;
                display: flex;
                align-items: center;
                gap: 20px;
                @media(max-width:520px){
                    width: 100%;
                }
                a{
                    text-decoration: none;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #303848;
                    color: white;
                    transition: .3s;
                    @media(max-width:520px){
                        width: 45px;
                        height: 45px;
                    }
                    &:hover{
                        transition: .3s;
                        background: var(--primaryColor);
                        border-color: var(--primaryColor);
                    }
                    svg{
                        width: 32px;
                        height: 32px;
                        @media(max-width:520px){
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
            }
        }
        .footerCol2{
            display: flex;
            flex-wrap: wrap;
            gap: 50px;
            @media(max-width:1024px){
                flex: 100%;
                margin-top: 80px;
            }
            .footerBox1{
                display: flex;
                flex-direction: column;
                width: 200px;
                @media(max-width:1024px){
                    width: calc(100% / 3 - 35px);
                }
                @media(max-width:520px){
                    width: 100%;
                }
                h2{
                    font-size: 20px;
                    color: white;
                    font-weight: 400;
                }
                ul{
                    width: 100%;
                    margin-top: 20px;
                    display: flex;
                    flex-wrap: wrap;
                    row-gap: 20px;
                    li{
                        width: 100%;
                        list-style: none;
                        a{
                            text-decoration: none;
                            color: #9fa6bb;
                            font-size: 16px;
                            transition: .3s;
                            &:hover{
                                color: white;
                                transition: .3s;
                            }
                        }
                    }
                }
            }
            .footerBox2{
                display: flex;
                flex-direction: column;
                width: 200px;
                @media(max-width:1024px){
                    width: calc(100% / 3 - 35px);
                }
                @media(max-width:520px){
                    width: 100%;
                }
                h2{
                    font-size: 20px;
                    color: white;
                    font-weight: 400;
                    width: fit-content;
                }
                ul{
                    width: fit-content;
                    margin-top: 20px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    row-gap: 20px;
                    li{
                        flex: 100%;
                        list-style: none;
                        a{
                            text-decoration: none;
                            color: #9fa6bb;
                            font-size: 16px;
                            transition: .3s;
                            &:hover{
                                color: white;
                                transition: .3s;
                            }
                        }
                    }
                }
            }
            .footerBox3{
                display: flex;
                flex-direction: column;
                width: 120px;
                @media(max-width:1024px){
                    width: calc(100% / 3 - 35px);
                }
                @media(max-width:520px){
                    width: 100%;
                }
                h2{
                    font-size: 20px;
                    color: white;
                    font-weight: 400;
                    width: fit-content;
                }
                ul{
                    width: fit-content;
                    margin-top: 20px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    row-gap: 20px;
                    li{
                        flex: 100%;
                        list-style: none;
                        a{
                            text-decoration: none;
                            color: #9fa6bb;
                            font-size: 16px;
                            transition: .3s;
                            &:hover{
                                color: white;
                                transition: .3s;
                            }
                        }
                    }
                }
            }
        }
    }
    .footerStrip{
        width: 100%;
        border-top: 1px solid #303848;
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-top: 20px;
        .footerStripCol1{
            display: flex;
            p{
                color: white;
                font-size: 14px;
                font-weight: 300;
            }
        }
        .footerStripCol2{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;
            p{
                color: white;
                font-size: 14px;
                font-weight: 300;
            }
            svg{
                color: white;
                width: 20px;
                height: 20px;
            }
            .footerStripLogo{
                width: 60px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    filter: grayscale(1);
                }
            }
        }
    }
}


.jobBox{
    width: calc(100% / 2 - 15px);
    // border: 1px solid #eee;
    display: flex;
    flex-direction: column;
    // padding: 15px;
    border-radius: 15px;
    text-decoration: none;
    background: white;
    position: relative;
    overflow: hidden;
    transition: .3s;
    // box-shadow: 0 6px 12px rgba(30,10,58,.04);
    z-index: 0;
    // @media(max-width:1800px){
    //     width: calc(100% / 3 - 14px);
    // }
    @media(max-width:1200px){
        width: calc(100% / 2 - 10px);
    }
    @media(max-width:768px){
        width: 100%;
    }
    // &:nth-of-type(odd){
    //     background: #e7f4ee;
    // }
    // &:nth-of-type(even){
    //     background: #f0f2ff;
    // }
    &:hover{
        transition: .3s;
        box-shadow: 5px 5px 20px #0000000d;
        border-color: var(--primaryColor);
    }
    &::after{
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        background: #f0f2ff;
        // background: linear-gradient(45deg, #e7f4ee, #f0f2ff);
        background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
    }
    .jobBoxLink{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }
    .jobBoxHead{
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 15px;
        .jobBoxHeadCol1{
            display: flex;
            align-items: center;
            // width: calc(100% - 75px);
            .jobBoxProfile{
                width: 100%;
                display: flex;
                align-items: center;
                gap: 10px;
                .jobBoxProfileIcon{
                    width: 50px;
                    height: 50px;
                    padding: 3px;
                    border-radius: 15px;
                    // background-image: linear-gradient(to top, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%);
                    overflow: hidden;
                    // border: 1px solid #eee;
                    background: white;
                    display: flex;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                .jobBoxProfileTitle{
                    display: flex;
                    flex-direction: column;
                     width: calc(100% - 60px);
                   // width: 100%;
                    .jobCompany{
                        font-size: 14px;
                        font-weight: 400;
                        color: var(--secondaryTextColor);
                        color: #818181;
                        width: 100%;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        display: none;
                    }
                    .jobTitleName{
                        font-size: 18px;
                        font-weight: 500;
                        color: var(--primaryColor);
                        // margin-top: 5px;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                }
            }
        }
        .jobBoxHeadCol2{
            // width: 75px;
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            .jobBoxSaveBtn{
                width: 35px;
                height: 35px;
                border-radius: 50%;
                // background: #f9f9f9;
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--secondaryTextColor);
                position: relative;
                z-index: 2;
                cursor: pointer;
                transition: .3s;
                background: white;
                @media(max-width:520px){
                    width: 30px;
                    height: 30px;
                }
                &:hover{
                    transition: .3s;
                    color: black;
                    background: #eee;
                }
                svg{
                    width: 20px;
                    height: 20px;
                    @media(max-width:520px){
                        width: 15px;
                        height: 15px;
                    }
                }
            }
            .jobBoxShareBtn{
                width: 35px;
                height: 35px;
                border-radius: 50%;
                // background: #f9f9f9;
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--secondaryTextColor);
                position: relative;
                z-index: 2;
                cursor: pointer;
                transition: .3s;
                background: white;
                @media(max-width:520px){
                    width: 30px;
                    height: 30px;
                }
                &:hover{
                    transition: .3s;
                    color: var(--primaryColor);
                    background: #eee;
                }
                svg{
                    width: 20px;
                    height: 20px;
                    @media(max-width:520px){
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }
    }
    .jobBoxBody{
        width: 100%;
        border-top: 1px solid #fff;
        padding: 15px 15px 0px 15px;
        ul{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            row-gap: 15px;
            column-gap: 25px;
            li{
                list-style: none;
                display: flex;
                align-items: center;
                gap: 8px;
                position: relative;
                z-index: 0;
                &:nth-of-type(1){
                    &::after{
                        display: none;
                    }
                }
                &::after{
                    position: absolute;
                    content: '';
                    height: 100%;
                    width: 1px;
                    background: #eee;
                    left: -12.5px;
                    top: 0;
                }
                .icon{
                    width: 14px;
                    height: 14px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: var(--secondaryTextColor);
                    svg{
                        width: 14px;
                        height: 14px;
                    }
                }
                p{
                    font-size: 14px;
                    font-weight: 500;
                    color: var(--secondaryTextColor);
                }
            }
            // .location{
            //     width: 100%;
            //     &::after{
            //         display: none;
            //     }
            // }
            .openings{
                background: #E7FBE6;
                padding: 4px 10px;
                border-radius: 50px;
                p{
                    color: #067c06;
                }
            }
        }
        .jobDescription{
            width: 100%;
            margin-top: 15px;
            display: flex;
            align-items: flex-start;
            gap: 8px;
            .icon{
                width: 14px;
                height: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 2px;
                color: var(--secondaryTextColor);
                svg{
                    width: 14px;
                    height: 14px;
                }
            }
            p{
                width: calc(100% - 22px);
                font-size: 13px;
                color: var(--secondaryTextColor);
                line-height: 1.4;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
    }
    .jobBoxFooter{
        width: 100%;
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #fff;
        margin-top: 15px;
        .jobBoxFooterCol1{
            display: flex;
            align-items: center;
            gap: 10px;
            // .jobOpenings{
            //     padding: 6px 15px;
            //     background: #eaf2f3;
            //     border-radius: 3px;
            //     font-size: 14px;
            //     display: none;
            // }
            .postedDate{
                display: flex;
                align-items: center;
                p{
                    font-size: 12px;
                    // color: var(--secondaryTextColor);
                    color: black;
                    opacity: .7;
                }
            }
        }
        .jobBoxFooterCol2{
            display: flex;
            align-items: center;
            .jobBoxApplyBtn{
                padding: 0px 20px;
                height: 35px;
                background: var(--primaryColor);
                color: white;
                font-size: 14px;
                border-radius: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 500;
                transition: .3s;
                position: relative;
                z-index: 2;
                cursor: pointer;
                text-decoration: none;
                // display: none;
                &:hover{
                    transition: .3s;
                    opacity: .7;
                }
            }
            .jobBoxApplyBtnDisabled{
                cursor: not-allowed;
                opacity: .4;
            }
        }
    }
}
.loaded{
    .jobBoxHead{
        .jobBoxHeadCol1{
            .jobBoxProfile{
                .jobBoxProfileIcon{
                    position: relative;
                    z-index: 0;
                    background: #f5f5f5;
                    &::after{
                        position: absolute;
                        content: '';
                        height: 100%;
                        width: 100%;
                        top: 0;
                        left: -100%;
                        z-index: -1;
                        background: #dedede8c;
                        box-shadow: 0 0 50px #00000050;
                        filter: blur(15px);
                        opacity: .5;
                        animation: lazyLoading1 1.5s linear infinite;
                        @keyframes lazyLoading1 {
                            0%{
                                left: -100%;
                            }
                            100%{
                                left: 100%;
                            }
                        }
                    }
                    img{
                        display: none;
                    }
                }
                .jobBoxProfileTitle{
                    .jobCompany{
                        position: relative;
                        overflow: hidden;
                        &::after{
                            position: absolute;
                            content: '';
                            width: 100%;
                            height: 100%;
                            background: #f5f5f5;
                            border-radius: 5px;
                            z-index: 1;
                            left: 0;
                            top: 0;
                        }
                        &::before{
                            position: absolute;
                            content: '';
                            width: 100%;
                            height: 100%;
                            background: #f5f5f5;
                            border-radius: 5px;
                            z-index: 2;
                            left: 0;
                            top: 0;
                            background: #dedede;
                            box-shadow: 0 0 50px #00000024;
                            filter: blur(15px);
                            opacity: .5;
                            animation: lazyLoading3 1.5s linear infinite;
                            @keyframes lazyLoading3 {
                                0%{
                                    left: -100%;
                                }
                                100%{
                                    left: 100%;
                                }
                            }
                        }
                    }
                    .jobTitleName{
                        position: relative;
                        overflow: hidden;
                        &::after{
                            position: absolute;
                            content: '';
                            width: 100%;
                            height: 100%;
                            background: #f5f5f5;
                            border-radius: 5px;
                            z-index: 1;
                            left: 0;
                            top: 0;
                        }
                        &::before{
                            position: absolute;
                            content: '';
                            width: 100%;
                            height: 100%;
                            background: #f5f5f5;
                            border-radius: 5px;
                            z-index: 2;
                            left: 0;
                            top: 0;
                            background: #dedede;
                            box-shadow: 0 0 50px #00000024;
                            filter: blur(15px);
                            opacity: .5;
                            animation: lazyLoading3 1.5s linear infinite;
                            @keyframes lazyLoading3 {
                                0%{
                                    left: -100%;
                                }
                                100%{
                                    left: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
        .jobBoxHeadCol2{
            .jobBoxSaveBtn{
                position: relative;
                overflow: hidden;
                &::after{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    background: #f5f5f5;
                    border-radius: 5px;
                    z-index: 1;
                    left: 0;
                    top: 0;
                }
                &::before{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    background: #f5f5f5;
                    border-radius: 5px;
                    z-index: 2;
                    left: 0;
                    top: 0;
                    background: #dedede;
                    box-shadow: 0 0 50px #00000024;
                    filter: blur(15px);
                    opacity: .5;
                    animation: lazyLoading3 1.5s linear infinite;
                    @keyframes lazyLoading3 {
                        0%{
                            left: -100%;
                        }
                        100%{
                            left: 100%;
                        }
                    }
                }
            }
            .jobBoxShareBtn{
                position: relative;
                overflow: hidden;
                &::after{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    background: #f5f5f5;
                    border-radius: 5px;
                    z-index: 1;
                    left: 0;
                    top: 0;
                }
                &::before{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    background: #f5f5f5;
                    border-radius: 5px;
                    z-index: 2;
                    left: 0;
                    top: 0;
                    background: #dedede;
                    box-shadow: 0 0 50px #00000024;
                    filter: blur(15px);
                    opacity: .5;
                    animation: lazyLoading3 1.5s linear infinite;
                    @keyframes lazyLoading3 {
                        0%{
                            left: -100%;
                        }
                        100%{
                            left: 100%;
                        }
                    }
                }
            }
        }
    }
    .jobBoxBody{
        ul{
            li{
                position: relative;
                overflow: hidden;
                &::after{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    background: #f5f5f5;
                    border-radius: 5px;
                    z-index: 1;
                    left: 0;
                    top: 0;
                    display: block !important;
                }
                &::before{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    background: #f5f5f5;
                    border-radius: 5px;
                    z-index: 2;
                    left: 0;
                    top: 0;
                    background: #dedede;
                    box-shadow: 0 0 50px #00000024;
                    filter: blur(15px);
                    opacity: .5;
                    animation: lazyLoading3 1.5s linear infinite;
                    @keyframes lazyLoading3 {
                        0%{
                            left: -100%;
                        }
                        100%{
                            left: 100%;
                        }
                    }
                }
            }
        }
        .jobDescription{
            position: relative;
            overflow: hidden;
            &::after{
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                background: #f5f5f5;
                border-radius: 5px;
                z-index: 1;
                left: 0;
                top: 0;
            }
            &::before{
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                background: #f5f5f5;
                border-radius: 5px;
                z-index: 2;
                left: 0;
                top: 0;
                background: #dedede;
                box-shadow: 0 0 50px #00000024;
                filter: blur(15px);
                opacity: .5;
                animation: lazyLoading3 1.5s linear infinite;
                @keyframes lazyLoading3 {
                    0%{
                        left: -100%;
                    }
                    100%{
                        left: 100%;
                    }
                }
            }
        }
    }
    .jobBoxFooter{
        .jobBoxFooterCol1{
            .postedDate{
                position: relative;
                overflow: hidden;
                &::after{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    background: #f5f5f5;
                    border-radius: 5px;
                    z-index: 1;
                    left: 0;
                    top: 0;
                }
                &::before{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    background: #f5f5f5;
                    border-radius: 5px;
                    z-index: 2;
                    left: 0;
                    top: 0;
                    background: #dedede;
                    box-shadow: 0 0 50px #00000024;
                    filter: blur(15px);
                    opacity: .5;
                    animation: lazyLoading3 1.5s linear infinite;
                    @keyframes lazyLoading3 {
                        0%{
                            left: -100%;
                        }
                        100%{
                            left: 100%;
                        }
                    }
                }
            }
            .jobOpenings{
                position: relative;
                overflow: hidden;
                &::after{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    background: #f5f5f5;
                    border-radius: 5px;
                    z-index: 1;
                    left: 0;
                    top: 0;
                }
                &::before{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    background: #f5f5f5;
                    border-radius: 5px;
                    z-index: 2;
                    left: 0;
                    top: 0;
                    background: #dedede;
                    box-shadow: 0 0 50px #00000024;
                    filter: blur(15px);
                    opacity: .5;
                    animation: lazyLoading3 1.5s linear infinite;
                    @keyframes lazyLoading3 {
                        0%{
                            left: -100%;
                        }
                        100%{
                            left: 100%;
                        }
                    }
                }
            }
        }
        .jobBoxFooterCol2{
            .jobBoxApplyBtn{
                position: relative;
                overflow: hidden;
                border-radius: 0;
                &::after{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    background: #f5f5f5;
                    // border-radius: 5px;
                    z-index: 1;
                    left: 0;
                    top: 0;
                }
                &::before{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    background: #f5f5f5;
                    // border-radius: 5px;
                    z-index: 2;
                    left: 0;
                    top: 0;
                    background: #dedede;
                    box-shadow: 0 0 50px #00000024;
                    filter: blur(15px);
                    opacity: .5;
                    animation: lazyLoading3 1.5s linear infinite;
                    @keyframes lazyLoading3 {
                        0%{
                            left: -100%;
                        }
                        100%{
                            left: 100%;
                        }
                    }
                }
            }
        }
    }
}

.backBtnStrip{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
    .backBtnStripCol1{
        display: flex;
        align-items: center;
        .backBtn{
            display: flex;
            align-items: center;
            color: var(--secondaryColor);
            gap: 10px;
            font-size: 16px;
            cursor: pointer;
            svg{
                width: 16px;
                height: 16px;
            }
        }
    }
}
.jobWraperLoadMore{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media(max-width:480px){
        margin-top: 30px;
    }
    .jobWraperLoadMoreBtn{
        padding: 10px 20px;
        background: #f9f9f9;
        color: var(--secondaryColor);
        font-size: 16px;
        border-radius: 10px;
        cursor: pointer;
        transition: .3s;
        &:hover{
            transition: .3s;
            opacity: .7;
        }
    }
}
.noDataFound{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white;
    padding: 40px 20px;
    border-radius: 15px;
    .noDataFoundIcon{
        width: 300px;
        height: 300px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--bgColor);
        color: #aaa;
        svg{
            width: 30px;
            height: 30px;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .noDataFoundDetails{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // margin-top: 15px;
        width: 100%;
        h3{
            font-size: 22px;
            font-weight: 500;
            text-align: center;
        }
        p{
            font-size: 14px;
            text-align: center;
            margin-top: 10px;
            color: var(--secondaryTextColor);
            line-height: 1.3;
            width: 75%;
            @media(max-width:768px){
                width: 100%;
            }
        }
        .noDataFoundClearFIlterBtn{
            background: #f9f9f9;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 45px;
            padding: 0px 25px;
            cursor: pointer;
            border-radius: 10px;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            transition: .3s;
            color: var(--secondaryColor);
            margin-top: 20px;
            text-decoration: none;
            gap: 10px;
            &:hover{
                transition: .3s;
                background: none;
            }
            svg{
                width: 17px;
                height: 17px;
            }
        }
    }
}

// deleteConfirmPopup
.deleteConfirmPopup{
    position: fixed;
    z-index: 210;
    width: 700px;
    background: white;
    border-radius: 15px;
    padding: 60px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    // display: none;
    @media(max-width:750px){
        width: 90%;
    }
    @media(max-width:480px){
        width: 95%;
        padding: 20px;
    }
    .deleteConfirmPopupMain{
        width: 100%;
        display: flex;
        flex-direction: column;
        h2{
            font-size: 22px;
            font-weight: 600;
            @media(max-width:480px){
                font-size: 20px;
            }
        }
        .deleteConfirmPopupFooter{
            width: 100%;
            margin-top: 40px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 20px;
            .closeDeleteConfirmPopup{
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-weight: 600;
                color: var(--primaryColor);
                cursor: pointer;
                padding: 0px 20px;
                border-radius: 10px;
                background: #f9f9f9;
                transition: .3s;
                &:hover{
                    transition: .3s;
                    background: #fff;
                }
            }
            .confirmDeleteConfirmPopup{
                height: 40px;
                padding: 0px 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-weight: 600;
                color: white;
                background: var(--primaryColor);
                border-radius: 10px;
                cursor: pointer;
                transition: .3s;
                &:hover{
                    transition: .3s;
                    opacity: .7;
                }
            }
        }
    }
}

.toast-success .Toastify__progress-bar {
    background-color: #4c5caf; /* Example: Green color for success */
}

.toast-error .Toastify__progress-bar {
    background-color: #f44336; /* Example: Red color for error */
}

// jobAppliedSuccessPopup
.jobAppliedSuccessPopup{
    position: fixed;
    z-index: 200;
    width: 700px;
    background: white;
    border-radius: 15px;
    padding: 60px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    overflow: hidden;
    @media(max-width:750px){
        width: 90%;
    }
    @media(max-width:480px){
        width: 95%;
        padding: 20px;
    }
    &::after{
        position: absolute;
        content: '';
        z-index: -1;
        background: #f2fafa;
        height: 60%;
        width: 100%;
        bottom: 0;
        left: 0;
    }
    .jobAppliedSuccessPopupMain{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .jobAppliedSuccessPopupImg{
            width: 200px;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .jobAppliedSuccessPopupDetails{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 20px;
            h2{
                font-size: 30px;
                font-weight: 600;
                text-align: center;
            }
            p{
                text-align: center;
                font-size: 16px;
                color: var(--secondaryTextColor);
                margin-top: 15px;
                line-height: 1.5;
                width: 75%;
                font-weight: 300;
                @media(max-width:550px){
                    width: 85%;
                }
                @media(max-width:480px){
                    width: 100%;
                }
            }
            .okBtn{
                padding: 10px 30px;
                background: var(--primaryColor);
                color: white;
                font-size: 18px;
                border-radius: 10px;
                cursor: pointer;
                margin-top: 30px;
                transition: .3s;
                text-decoration: none;
                &:hover{
                    transition: .3s;
                    opacity: .7;
                }
            }
        }
    }
}

// profileFormPopup
.profileFormPopup{
    position: fixed;
    z-index: 200;
    width: 700px;
    background: white;
    border-radius: 15px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    @media(max-width:750px){
        width: 90%;
    }
    @media(max-width:480px){
        width: 95%;
    }
    .profileFormPopupHead{
        width: 100%;
        padding: 15px 50px;
        border-bottom: 1px solid #eee;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media(max-width:480px){
            padding: 12px 15px;
        }
    }
    .profileFormPopupBody{
        width: 100%;
        max-height: 65vh;
        overflow-y: auto;
        padding: 15px 50px;
        @media(max-width:480px){
            padding: 12px 15px;
        }
        form{
            width: 100%;
            display: flex;
            column-gap: 40px;
            row-gap: 30px;
            flex-wrap: wrap;
            .formGroup{
                width: calc(100% / 2 - 20px);
                display: flex;
                flex-direction: column;
                @media(max-width:550px){
                    width: 100%;
                }
                label{
                    font-size: 14px;
                    font-weight: 500;
                    color: var(--secondaryTextColor);
                }
                input{
                    width: 100%;
                    height: 40px;
                    border: 1px solid #aaa;
                    margin-top: 10px;
                    border-radius: 5px;
                    outline: none;
                    padding: 0px 20px;
                    font-size: 14px;
                    font-weight: 500;
                    &:focus{
                        border-color: var(--primaryColor);
                    }
                    &:disabled{
                        background: var(--bgColor);
                    }
                }
                .phoneInput{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    input{
                        border-radius: 0px 5px 5px 0px;
                    }
                    select{
                        width: fit-content;
                        height: 40px;
                        background: #ececec;
                        border: 1px solid #aaa;
                        border-right: none;
                        border-radius: 5px 0px 0px 5px;
                        outline: none;
                        margin-top: 10px;
                        padding: 0px 5px;
                        outline: none;
                        width:fit-content;
                        font-size: 12px;
                        font-weight: 500;
                        @media(max-width:520px){
                            height: 50px;
                        }
                    }
                }
                textarea{
                    width: 100%;
                    height: 100px;
                    border: 1px solid #aaa;
                    margin-top: 10px;
                    border-radius: 5px;
                    outline: none;
                    padding: 10px 20px;
                    font-size: 14px;
                    font-weight: 500;
                    &:focus{
                        border-color: var(--primaryColor);
                    }
                    &:disabled{
                        background: var(--bgColor);
                    }
                }
                .formRadioGroup{
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    row-gap: 25px;
                    column-gap: 40px;
                    margin-top: 20px;
                    .formRadioGroupBox{
                        min-width: calc(100% / 2 - 20px);
                        max-width: 100%;
                        input{
                            display: none;
                        }
                        input:checked ~ label{
                            .radioIcon{
                                border-color: var(--primaryColor);
                                &::after{
                                    background: var(--primaryColor);
                                    opacity: 1;
                                    transform: scale(1);
                                }
                            }
                        }
                        label{
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            cursor: pointer;
                            &:hover{
                                .radioIcon{
                                    &::after{
                                        opacity: 1;
                                        transition: .2s;
                                    }
                                }
                            }
                            .radioIcon{
                                width: 20px;
                                height: 20px;
                                border: 2px solid #aaa;
                                border-radius: 50px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                position: relative;
                                &::after{
                                    position: absolute;
                                    content: '';
                                    width: 10px;
                                    height: 10px;
                                    border-radius: 50px;
                                    background: #ddd;
                                    opacity: 0;
                                    transition: .2s;
                                    transform: scale(0);
                                }
                            }
                            .radioText{
                                font-size: 14px;
                                font-weight: 400;
                                width: calc(100% - 30px);
                            }
                        }
                    }
                    .radioGroup-Col-3{
                        min-width: calc(100% / 3 - 27px);
                    }
                }
                .formRadioGroupButton{
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    row-gap: 20px;
                    column-gap: 20px;
                    margin-top: 10px;
                    .formRadioGroupBoxButton{
                        input{
                            display: none;
                        }
                        input:checked ~ label{
                            background: var(--secondaryColor);
                            color: white;
                            border-color: var(--secondaryColor);
                        }
                        label{
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            cursor: pointer;
                            font-size: 16px;
                            font-weight: 400;
                            padding: 6px 20px;
                            border: 1px solid #aaa;
                            border-radius: 50px;
                            transition: .3s;
                            &:hover{
                                transition: .3s;
                                border-color: var(--secondaryColor);
                                color: var(--secondaryColor);
                            }
                        }
                    }
                }
                .formGroupDorpdownList{
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    column-gap: 40px;
                    row-gap: 20px;
                    // margin-top: 5px;
                    .formGroupDorpdownListBox{
                        width: 100%;
                        .customSelect{
                            margin-top: 10px;
                        }
                        .css-1cfo1cf{
                            margin: 0;
                            padding: 0;
                        }
                        .css-13cymwt-control{
                            border: 1px solid #aaa;
                        }
                        // .css-1wy0on6{
                        //     display: none !important;
                        // }
                        .css-19bb58m{
                            margin: 0;
                            padding: 0;
                        }
                        .css-hlgwow{
                            padding: 0px 18px;
                            input{
                                height: 40px;
                            }
                        }
                        .css-t3ipsp-control{
                            outline: none !important;
                            border: 1px solid var(--primaryColor);
                            box-shadow: none !important;
                            &:hover{
                                border-color: var(--primaryColor) !important;
                            }
                            &:focus{
                                border-color: var(--primaryColor) !important;
                            }
                        }
                        .css-1u9des2-indicatorSeparator{
                            display: none;
                        }
                    }
                }
                .formTable{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    .formTableHead{
                        width: 100%;
                        display: flex;
                        display: none;
                        @media(max-width:1250px){
                            display: none;
                        }
                        .formTableHeadBox{
                            width: 100%;
                            border-left: 1px solid #aaa;
                            border-top: 1px solid #aaa;
                            border-bottom: 1px solid #aaa;
                            padding: 5px;
                            background: #cdf2ff;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 14px;
                            text-align: center;
                            &:nth-of-type(1){
                                border-radius: 5px 0px 0px 0px;
                            }
                            &:nth-last-of-type(1){
                                border-radius: 0px 5px 0px 0px;
                                border-right: 1px solid #aaa;
                            }
                        }
                    }
                    .formTableBody{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 15px;
                        @media(max-width:1250px){
                            gap: 15px;
                        }
                        .formTableBodyRow{
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            border-top: 1px solid #aaa;
                            @media(max-width:1250px){
                                flex-direction: column;
                                border-top: 1px solid #aaa;
                            }
                            &:nth-last-of-type(1){
                                .formTableBodyBox{
                                    border-radius: 0px 0px 5px 5px;
                                }
                            }
                            .formTableBodyBox{
                                width: 100%;
                                border-bottom: 1px solid #aaa;
                                border-left: 1px solid #aaa;
                                padding: 5px;
                                border-right: 1px solid #aaa;
                                padding: 10px;
                                @media(max-width:1250px){
                                    border-right: 1px solid #aaa;
                                    padding: 10px;
                                }
                                &:nth-last-of-type(1){
                                    border-right: 1px solid #aaa;
                                }
                                .formTableLabel{
                                    display: none;
                                    display: block;
                                    padding: 5px 0px;
                                    @media(max-width:1250px){
                                        display: block;
                                        padding: 5px 0px;
                                    }
                                }
                                input{
                                    margin-top: 0;
                                    height: 35px;
                                    padding: 0px 10px;
                                    font-size: 16px;
                                    margin-top: 5px;
                                    @media(max-width:1250px){
                                        margin-top: 5px;
                                    }
                                }
                                .MuiStack-root{
                                    padding: 0;
                                    overflow: inherit;
                                }
                                .addformTableBodyRowBtn{
                                    background: #eafaea;
                                    color: #008d00;
                                    width: fit-content;
                                    height: 35px;
                                    border-radius: 5px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    padding: 0px 10px;
                                    cursor: pointer;
                                    font-size: 14px;
                                    font-weight: 500;
                                    transition: .3s;
                                    border: none;
                                    outline: none;
                                    &:hover{
                                        transition: .3s;
                                        background: none;
                                    }
                                }
                                .deleteformTableBodyRowBtn{
                                    background: #faeaea;
                                    color: #ff0000;
                                    width: fit-content;
                                    height: 35px;
                                    border-radius: 5px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    padding: 0px 10px;
                                    cursor: pointer;
                                    font-size: 14px;
                                    font-weight: 500;
                                    border: none;
                                    outline: none;
                                    transition: .3s;
                                    &:hover{
                                        transition: .3s;
                                        background: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .MuiFormControl-root{
                width: 100%;
                max-width: 100%;
                min-width: 100%;
                input{
                    margin-top: 0;
                    border: none;
                }
                .MuiOutlinedInput-notchedOutline{
                    border-color: #aaa;
                }
            }
            .col-1{
                width: 100%;
                .formGroupDorpdownList{
                    .formGroupDorpdownListBox{
                        width: calc(100% / 2 - 20px);
                        @media(max-width:550px){
                            width: 100%;
                        }
                    }
                    .dorpdownList-Col-3{
                        width: calc(100% / 2 - 20px);
                        @media(max-width:550px){
                            width: 100%;
                        }
                    }
                }
            }
            .uploadResumeBox{
                width: 100%;
                border: 2px dashed #ebdeba;
                padding: 50px;
                border-radius: 10px;
                background: #fffbf0;
                transition: .3s;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                @media(max-width:480px){
                    padding: 20px;
                }
                &:hover{
                    transition: .3s;
                    background: #fff9da;
                }
            }
            .uploadResumeAlert{
                width: 100%;
                padding: 10px 20px;
                background: #FFD5CD;
                // border: 1px solid #ffe5e5;
                font-size: 14px;
                line-height: 1.5;
                margin-top: -20px;
                border-radius: 3px;
                // color: white;
                @media(max-width:1024px){
                    font-size: 12px;
                    padding: 10px;
                }
            }
            .resumeList{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                margin-top: -20px;
                .resumeListBox{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 10px;
                    border: 1px solid #ccc;
                    padding: 10px 20px;
                    border-radius: 10px;
                    .resumeListBoxCol1{
                        display: flex;
                        flex-direction: column;
                        p,a{
                            font-size: 14px;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            width: 100%;
                            font-weight: 500;
                        }
                    }
                    .resumeListBoxCol2{
                        display: flex;
                        align-items: center;
                        gap: 15px;
                        .resumeListbtn{
                            width: 35px;
                            height: 35px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: none;
                            outline: none;
                            cursor: pointer;
                            border-radius: 50%;
                            background: var(--bgColor);
                            transition: .3s;
                            color: var(--secondaryColor);
                            &:hover{
                                transition: .3s;
                                background: #eee;
                            }
                            svg{
                                width: 15px;
                                height: 15px;
                            }
                        }
                    }
                }
            }
            .eductionForm{
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 60px;
                padding-bottom: 20px;
                .eductionFormBox{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    // border-bottom: 1px solid #eee;
                    position: relative;
                    &:nth-last-of-type(1){
                        &::after{
                            display: none;
                        }
                    }
                    &::after{
                        position: absolute;
                        bottom: -30px;
                        width: 100%;
                        height: 1px;
                        background: #eee;
                        content: '';
                        left: 0;
                    }
                    .eductionFormBoxHead{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        cursor: pointer;
                        h3{
                            font-size: 16px;
                            font-weight: 600;
                            color: var(--secondaryColor);
                        }
                        svg{
                            width: 18px;
                            height: 18px;
                            color: var(--secondaryColor);
                        }
                    }
                    .eductionFormBoxBody{
                        width: 100%;
                        flex-wrap: wrap;
                        margin-top: 20px;
                        column-gap: 40px;
                        row-gap: 30px;
                        display: flex;
                    }
                    .eductionFormBoxBodyActive{
                        display: flex;
                    }
                }
            }
            .customDropdown {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                .customDropdownHead{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    position: relative;
                    margin-top: 10px;
                    input{
                        margin-top: 0;
                        &::-webkit-search-cancel-button{
                            position:relative;
                            right:20px;    
                        }
                        &::placeholder{
                            color: #808080;
                        }
                        &:focus ~ .customDropdownHeadcon{
                            svg{
                                color: var(--primaryColor);
                            }
                        }
                    }
                    .customDropdownHeadcon{
                        position: absolute;
                        width: 15px;
                        height: 15px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        right: 10px;
                        svg{
                            width: 15px;
                            height: 15px;
                            color: #cccccc;
                        }
                    }
                }
                .dropdownMenu {
                    position: absolute;
                    top: calc(100% + 5px);
                    left: 0;
                    right: 0;
                    border: 1px solid #ddd;
                    background-color: white;
                    box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.09);;
                    z-index: 10;
                    max-height: 150px;
                    overflow-y: auto;
                    list-style: none;
                    padding: 0px;
                    margin: 0;
                    border-radius: 5px;
                    li {
                        padding: 8px 12px;
                        cursor: pointer;
                        &:nth-of-type(1){
                            margin-top: 5px;
                        }
                        &:nth-last-of-type(1){
                            margin-bottom: 5px;
                        }
                        &:hover {
                            background-color: #eee;
                        }
                    }
                    .selected {
                        background-color: #d2e2ff !important;
                    }
                }
                .dropdownMenu li:hover, .dropdownMenu li.selected {
                    background-color: #eee;
                }
            } 
            .personalDetailsForm{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                column-gap: 40px;
                row-gap: 30px;
            }
            .languageDetailsForm{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                gap: 30px;
                .languageDetailsFormBox{
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    column-gap: 40px;
                    row-gap: 30px;
                    .languageDetailsFormBoxFooter{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        gap: 20px;
                        .languageDetailsFormBoxFooterCol1{
                            display: flex;
                            .formGroupCheckboxArea{
                                width: 100%;
                                ul{
                                    width: 100%;
                                    display: flex;
                                    flex-wrap: wrap;
                                    row-gap: 25px;
                                    column-gap: 60px;
                                    li{
                                        list-style: none;
                                        input{
                                            display: none;
                                        }
                                        input:checked ~ label{
                                            &:hover{
                                                .checkboxIcon{
                                                    &::after{
                                                        border-color: #fff;
                                                    }
                                                }
                                            }
                                            .checkboxIcon{
                                                border-color: var(--primaryColor);
                                                background: var(--primaryColor);
                                                &::after{
                                                    opacity: 1;
                                                }
                                            }
                                        }
                                        label{
                                            display: flex;
                                            align-items: center;
                                            gap: 10px;
                                            cursor: pointer;
                                            &:hover{
                                                .checkboxIcon{
                                                    &::after{
                                                        opacity: 1;
                                                        border-color: #ddd;
                                                    }
                                                }
                                            }
                                            .checkboxIcon{
                                                width: 20px;
                                                height: 20px;
                                                border: 2px solid #aaa;
                                                border-radius: 3px;
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                                position: relative;
                                                &::after{
                                                    position: absolute;
                                                    content: '';
                                                    width: 5px;
                                                    height: 9px;
                                                    opacity: 0;
                                                    transition: .2s;
                                                    border-bottom: 2px solid #fff;
                                                    border-right: 2px solid #fff;
                                                    transform: rotate(45deg);
                                                    top: 1px;
                                                }
                                            }
                                            .checkboxText{
                                                font-size: 16px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .languageDetailsFormBoxFooterCol2{
                            display: flex;
                            .deleteLanguageBtn{
                                font-size: 16px;
                                font-weight: 600;
                                color: red;
                                cursor: pointer;
                            }
                        }
                    }
                }
                .addLanguageDetailsFormBoxArea{
                    width: 100%;
                    display: flex;
                    .addLanguageBtn{
                        font-size: 16px;
                        font-weight: 600;
                        color: rgb(0, 141, 0);
                        cursor: pointer;
                        background: rgb(234, 250, 234);
                        padding: 12px 20px;
                        border-radius: 10px;
                        transition: .3s;
                        &:hover{
                            background: white;
                            transition: .3s;
                        }
                    }
                }
            }
            .profileImageBoxMain{
                width: 100%;
                display: flex;
                align-items: flex-start;
                gap: 40px;
                margin-top: 10px;
                @media(max-width:550px){
                    flex-wrap: wrap;
                }
                .profileImageBoxMainCol1{
                    width: calc(100% / 2 - 20px);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    @media(max-width:550px){
                        width: 100%;
                    }
                    .profileImageBox{
                        width: 100%;
                        height: 250px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        overflow: hidden;
                        border: 2px dashed #ddd;
                        background: var(--bgColor);
                        border-radius: 5px;
                        cursor: pointer;
                        transition: .3s;
                        padding: 20px;
                        position: relative;
                        @media(max-width:550px){
                            height: 150px;
                        }
                        &:hover{
                            border-color: #00a545;
                            transition: .3s;
                        }
                        .profileImageBoxCanvas{
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                        }
                        p{
                            font-size: 18px;
                            font-weight: 600;
                            text-align: center;
                            line-height: 1.5;
                            @media(max-width:550px){
                                font-size: 14px;
                            }
                        }
                    }
                    .profileImageBoxBtnArea{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        margin-top: 20px;
                        justify-content: center;
                        gap: 15px;
                        .profileImageResetBtn{
                            height: 35px;
                            width: 35px;
                            font-size: 12px;
                            font-weight: 500;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: #eee;
                            border-radius: 3px;
                            cursor: pointer;
                            transition: .3s;
                            gap: 10px;
                            transform: scaleX(-1);
                            svg{
                                color: black;
                                width: 18px;
                                height: 18px;
                            }
                            &:hover{
                                transition: .3s;
                                opacity: .7;
                            }
                        }
                        .profileImageSaveBtn{
                            height: 35px;
                            padding: 0px 20px;
                            font-size: 12px;
                            font-weight: 500;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: var(--secondaryColor);
                            color: white;
                            border-radius: 3px;
                            cursor: pointer;
                            transition: .3s;
                            gap: 10px;
                            svg{
                                color: white;
                                width: 18px;
                                height: 18px;
                            }
                            &:hover{
                                transition: .3s;
                                opacity: .7;
                            }
                        }
                    }
                }
                .profileImageBoxMainCol2{
                    width: calc(100% / 2 - 20px);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    @media(max-width:550px){
                        width: 100%;
                    }
                    .profileImageView{
                        width: 100%;
                        height: 250px;
                        padding: 20px;
                        border-radius: 5px;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        background: var(--bgColor);
                        @media(max-width:550px){
                            height: 150px;
                        }
                        .profileImageViewBox{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                        }
                        h2{
                            text-align: center;
                        }
                        img{
                            width: 150px;
                            height: 150px;
                            border-radius: 50%;
                            border: 2px solid #eee;
                            margin-top: 10px;
                            @media(max-width:550px){
                                height: 60px;
                                width: 60px;
                            }
                        }
                    }
                }
            }
            .line{
                width: 100%;
                height: 1px;
                background: #eee;
            }
            @media(max-width:1024px){
                .mobDisplayNone{
                    display: none;
                }
            }
        }
    }
    .profileFormPopupFooter{
        width: 100%;
        padding: 15px 50px;
        border-top: 1px solid #eee;
        @media(max-width:480px){
            padding: 12px 15px;
        }
        .formBtnArea{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 15px;
            .closeProfileFormPopup{
                padding: 0px 25px;
                height: 40px;
                background: var(--bgColor);
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                cursor: pointer;
                font-weight: 500;
                text-decoration: none;
                transition: .3s;
                &:hover{
                    transition: .3s;
                    opacity: .7;
                }
            }
            .submitProfileFormPopup{
                padding: 0px 25px;
                height: 40px;
                background: var(--primaryColor);
                color: white;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                cursor: pointer;
                font-weight: 500;
                text-decoration: none;
                border: none;
                outline: none;
                transition: .3s;
                &:hover{
                    transition: .3s;
                    opacity: .7;
                }
            }
        }
    }
}

// .fade-enter {
//     opacity: 0;
//     transform: translateX(100px);
//   }
  
//   .fade-enter-active {
//     opacity: 1;
//     transform: translateX(0);
//     transition: opacity 500ms, transform 500ms;
//   }
  
//   .fade-exit {
//     opacity: 1;
//     transform: translateX(0);
//   }
  
//   .fade-exit-active {
//     opacity: 0;
//     transform: translateX(100px);
//     transition: opacity 500ms, transform 500ms;
//   }
  

.loader{
    display: flex;
    align-items: center;
    gap: 5px;
    span{
        width: 5px;
        height: 5px;
        border-radius: 5px;
        background: white;
        animation: loadingBtnAnimation .8s infinite linear;
        &:nth-of-type(2){
            animation-delay: .1s;
        }
        &:nth-of-type(3){
            animation-delay: .2s;
        }
        &:nth-of-type(4){
            animation-delay: .3s;
        }
        @keyframes loadingBtnAnimation {
            0%{
                transform: translateY(0px);
                opacity: 1;
            }
            50%{
                transform: translateY(5px);
                opacity: .4;
            }
            100%{
                transform: translateY(0px);
                opacity: 1;
            }
        }
    }
}



.pageNotFound {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 0px;
    background-color: #f3f3f3;
    min-height: 100vh;
    text-align: center;
    color: #333;
    width: 100%;
    @media(max-width:550px){
        margin-top: 60px;
    }
     h1 {
        font-size: 3em;
        color: #faca2e;
        margin: 0;
        margin-top: 20px;
    }
    p{
        margin: 0;
        font-size: 16px;
        margin-top: 20px;
    }
    button{
        padding: 10px 30px;
        background: #000;
        color: white;
        border: none;
        outline: none;
        cursor: pointer;
        border-radius :10px;
        font-size: 16px;
        margin-top: 40px;
        transition: .3s;
        &:hover{
            transition: .3s;
            opacity:.7;
        }
    }
    .puzzleBox{
        width: 100%;
        display :flex;
        justify-content :center;
        align-items :center;
    }
    .puzzleGrid {
        display: grid;
        grid-template-columns: repeat(3, 100px);
        gap: 10px;
        margin-top: 20px;
    }
    .puzzleTile {
        width: 100px;
        height: 100px;
        background-color: #f3f3f3;
        font-size: 1.5rem;
        font-weight: bold;
        color: #333;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 5px;
        transition: background-color 0.3s;
    }
    .empty {
        background-color: #CADABF;
        cursor: default;
    }
    .message{
        padding: 20px 40px;
        border-radius: 10px;
        background: #FC8F54;
        color: white;
        margin: 0 auto;
        /* margin-top: 20px; */
        width: fit-content;
        position: fixed;
        transform: translateX(-50%);
        left: 50%;
        top: 100px;
        opacity: 1;
        @media(max-width:480px){
            width: 90%;
        }
    }
    .messageActive{
        transition: .3s;
        top: 50px;
        opacity: 1;
    }
    .eyes {
        display: flex;
        justify-content: center;
        gap: 2px;
    }
    .eye {
        width: 80px;
        height: 80px;
        background-color: #faca2e;
        border-radius: 50%;
        display: grid;
        place-items: center;
    }
    .pupil {
        width: 30px;
        height: 30px;
        background-color: #050505;
        border-radius: 50%;
        animation: movePupil 2s infinite ease-in-out;
        transform-origin: center center;
    }
  
    @keyframes movePupil {
        0%,
        100% {
            transform: translate(0, 0);
        }
        25% {
            transform: translate(-10px, -10px);
        }
        50% {
            transform: translate(10px, 10px);
        }
        75% {
            transform: translate(-10px, 10px);
        }
    }
}